import { Col, Row, Table, TablePaginationConfig } from 'antd';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import apiClient from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import LeftAngleIcon from '../../../assets/Icon/LeftAngleIcon';

const StudentUpdatesLog: React.FC = () => {
  const history = useHistory();
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [schools, setSchools] = React.useState([]);
  const [updateRecords, setUpdateRecords] = React.useState([]);

  const pagination = {
    total: totalRecords,
    showSizeChanger: true,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: ['10', '50', '100'],
    onShowSizeChange: async (currentPage, size) => await fetchStudentUpdatesLogs(currentPage, size),
    onChange: async (page, size) => await fetchStudentUpdatesLogs(page, size),
  } as TablePaginationConfig;

  const StudentUpdatesLogTableColumns = [
    {
      title: 'Updated On',
      dataIndex: 'reconciledOn',
      key: 'reconciledOn',
      width: '12%',
      render: (reconciledOn) => (
        <span data-test-id="reconcile_date">{reconciledOn ? new Date(reconciledOn * 1000).toLocaleString() : ''}</span>
      ),
    },
    {
      title: 'Updated By',
      dataIndex: 'reconciledBy',
      key: 'reconciledBy',
      width: '15%',
      render: (reconciledBy) => <span data-test-id="reconciled_by">{reconciledBy}</span>,
    },
    {
      title: 'Update',
      dataIndex: 'reconcileType',
      key: 'reconcileType',
      width: '8%',
      render: (reconcileType) => (
        <span data-test-id="reconcile_type">
          {reconcileType.includes('CREATED') ? 'Created' : reconcileType.includes('MATCHED') ? 'Matched' : ''}
        </span>
      ),
    },
    {
      title: 'School',
      dataIndex: 'school',
      key: 'school',
      width: '15%',
      render: (school) => <span data-test-id="school">{schools.find((x) => x.nid === school)?.name || ''}</span>,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      width: '10%',
      render: (lastName) => <span data-test-id="lastName">{lastName}</span>,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      width: '10%',
      render: (firstName) => <span data-test-id="firstName">{firstName}</span>,
    },
    {
      title: 'Student ID',
      dataIndex: 'hsStudentId',
      key: 'hsStudentId',
      width: '10%',
      render: (hsStudentId) => <span data-test-id="hsStudentId">{hsStudentId}</span>,
    },
    {
      title: 'Class Year',
      dataIndex: 'classYear',
      key: 'classYear',
      width: '10%',
      render: (classYear) => <span data-test-id="classYear">{classYear}</span>,
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      width: '10%',
      render: (isActive) => <span data-test-id="isActive">{isActive ? 'Active' : 'Inactive'}</span>,
    },
  ];

  const getSchools = async (): Promise<any> => {
    try {
      const { data } = await apiClient.get(`/highschools/v2?byType=district&includeInactive=true`);
      setSchools(data);
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Getting Student  Logs', 'Failure in getting data from server.');
      throw error;
    }
  };

  const mapReconcilehistory = (data: any): any => {
    return {
      page: data.page as number,
      pages: data.pages as number,
      search: data.search as string,
      sort: data.sort as string,
      sortDir: data.sortDir as string,
      total: data.total as string,
      records: data.records.map((record: any): any => {
        return {
          classYear: record.classYear as number,
          firstName: record.firstName as string,
          lastName: record.lastName as string,
          hsStudentId: record.hsStudentId as string,
          school: record.Naviance_School_ID as string,
          isActive: record.isActive as boolean,
          reconciledOn: record.reconciledOn as string,
          reconciledBy: record.reconciledBy as string,
          reconcileType: record.reconciledSK as string,
        };
      }),
    };
  };

  const fetchStudentUpdatesLogs = async (page = 1, size = 10) => {
    try {
      setLoading(true);
      let { data } = await apiClient.get(`/data-ingest/sis/reconcileHistory?page=${page}&size=${size}`);
      data = mapReconcilehistory(data);
      setUpdateRecords(data?.records || []);
      setTotalRecords(data?.total || 0);
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting Student Update Logs',
        'Failure in getting data from server.',
      );
    } finally {
      setLoading(false);
    }
  };

  React.useEffect(() => {
    void getSchools();
    void fetchStudentUpdatesLogs();
  }, []);

  return (
    <MainTemplate title="Student Updates Log" titleTestId="student_updates_log_title">
      <>
        <Row justify="space-between" align="middle">
          <Col span={4}>
            <div style={{ float: 'left', width: '30px' }}>
              <LeftAngleIcon />
            </div>
            <div style={{ float: 'left' }}>
              <a className="backbtn" onClick={() => history.goBack()}>
                Back to View Log
              </a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <p>Potential updates resolved between Naviance and PowerSchool products.</p>
            <p>This page will display 1 year of past updates.</p>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <div className="mainDataImportTable sisImportTable">
              <Table
                rowKey="hsStudentId"
                loading={loading}
                columns={StudentUpdatesLogTableColumns}
                dataSource={updateRecords}
                pagination={pagination}
                locale={{
                  emptyText: (
                    <div style={{ margin: '20px' }}>
                      <h1>No data found</h1>
                    </div>
                  ),
                }}
              ></Table>
            </div>
          </Col>
        </Row>
      </>
    </MainTemplate>
  );
};

export default StudentUpdatesLog;
