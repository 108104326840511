import type { ISingleJobRow, NavSchoolsType } from '../../../../../../libs/common-interfaces';
import { Row, Col, Space, Spin, Modal, Button, Alert } from 'antd';
import { CheckCircleFilled } from '@ant-design/icons';
import Title from 'antd/lib/typography/Title';
import Paragraph from 'antd/lib/typography/Paragraph';
import React from 'react';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import { showNotification, NotificationTypes } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { PluginStatus } from '../../utils/pluginVersion';
import { FrequencyDefinition, FrequencyNames } from '../../../../../../libs/common-interfaces';
import { IntegrationContext } from './../../utils/context';
import { StatusMessages } from '../../utils/jobStatus';

const frequencyDisplayMap = {
  THIRTY_MINUTES: '30 Minutes',
  ONE_HOUR: '1 Hour',
  FOUR_HOURS: '4 Hours',
  TWELVE_HOURS: '12 Hours',
  TWENTY_FOUR_HOURS: '24 Hours',
  ONE_TIME_IMPORT: 'One Time Import',
  ALUMNI_TRACKER: 'Alumni Tracker',
  ONE_WEEK: 'One Week',
  WEEKLY: 'Weekly',
  DAILY: 'Daily',
};

export const NotificationStyle = {
  backgroundColor: '#D9FCF1',
  borderLeft: 'inset',
  borderLeftColor: '#12D097',
};

interface SisStartImportProps extends WizardProps {
  type?: string;
  typeLabel?: string;
  includeSchools?: boolean;
  defaultFrequency?: FrequencyNames;
  enableAskImportPopUp?: boolean;
}

interface IngressResponseData {
  cron?: FrequencyNames;
  frequency?: FrequencyDefinition;
}

const SisStartImport = (props: SisStartImportProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);
  const [dataType, setDataType] = React.useState<string>('');
  const [frequency, setFrequency] = React.useState<string>('');
  const [schools, setSchools] = React.useState<string[]>([]);
  const [loading, setLoading] = React.useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const [askConfirmationModal, setAskConfirmationModal] = React.useState(false);
  const [isTestImport, setIsTestImport] = React.useState(false);
  const [disableStart, setDisableStart] = React.useState(true);
  const [startBtnLoading, setStartBtnLoading] = React.useState(true);
  const [isDisabling, setIsDisabling] = React.useState(false);
  const [isPluginDeprecated, setIsPluginDeprecated] = React.useState<boolean>(false);
  const [updateMessage, setUpdateMessage] = React.useState<string>(null);
  const [isWarning, setIsWarning] = React.useState<boolean>(true);
  const [allMappedSchoolsLength, setAllMappedSchoolsLength] = React.useState<number>(0);
  const [disableFooter, setHideFooterComp] = React.useState(true);
  const [runningData, setRunningData] = React.useState<any>({
    lastJob: {} as ISingleJobRow,
    runningJobs: [],
    connection: { working: false, configured: false },
    schoolsMapped: false,
  });

  const { pluginDetails, isLegacyCleverEnabled, userFullName, isCleverIntegration }: any = React.useContext(IntegrationContext);

  const { type, defaultFrequency, enableAskImportPopUp } = props;

  const askImportConfirmation = (isTestImport, enableAskImportPopUp = false) => {
    if (enableAskImportPopUp && !isTestImport) {
      setIsTestImport(isTestImport);
      setAskConfirmationModal(true);
    } else {
      startImport(isTestImport);
    }
  };

  const onAskOk = () => {
    startImport(isTestImport);
  };

  const onAskCancel = () => {
    //setIsTestImport(false);
    setAskConfirmationModal(false);
  };

  const startImport = async (isTestImport = false) => {
    try {
      setDisableStart(true);
      setAskConfirmationModal(false);
      await apiClient.post('/data-ingest/sis/start', {
        type: props.type,
        isTestImport,
        cron: runningData?.cron,
        author: userFullName,
      });
      if (!isTestImport) {
        setShowConfirmationModal(true);
      } else {
        showNotification(
          NotificationTypes.success,
          <Title level={5}>Test Import in Progress</Title>,
          <>
            <Paragraph>Large imports may take a few minutes to complete.</Paragraph>
            <Paragraph type="secondary">{new Date().toLocaleString()}</Paragraph>
          </>,
          NotificationStyle,
          <CheckCircleFilled style={{ color: '#12D097' }} />,
        );
        onOk();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Starting Job', 'Server Error');
    }
  };

  const onOk = async () => {
    props.continueFn();
  };

  function handleIngressFrequencyResponse(data: IngressResponseData) {
    const currentFrequency: FrequencyDefinition = data.frequency ?? { name: data.cron ?? defaultFrequency };

    setFrequency(frequencyDisplayMap[currentFrequency.name]);
    setHideFooterComp(false);
  }

  const fetchIngressAndJobs = async (): Promise<void> => {
    const ingressType = props.type ?? 'students';
    const includeSchools = props.includeSchools ?? false;
    try {
      const { data } = await apiClient.get(
        `/data-ingest/ingress/${ingressType}?includeJobs=true&includeSchools=${includeSchools}`,
      );
      if (isMountedRef.current) {
        handleIngressFrequencyResponse(data);
        setSchools(data.schools || []);
        if (!data.schools) {
          try {
            const { data } = await apiClient.get('/data-ingest/sis/parameter-group/schoolMapping');
            if (data.schoolMapping) {
              const mapping = data.schoolMapping.reduce((acc, value) => {
                const { sisId, nid, disabled } = value;
                acc[nid] = { nid, sisId, enabled: !disabled };
                return acc;
              }, {});
              const res = await apiClient.get('/highschools/v2?byType=district&includeInactive=true');
              const navSchools: NavSchoolsType[] = res.data.sort((a, b) =>
                a.name > b.name ? 1 : a.name < b.name ? -1 : 0,
              );
              const mappedSchools = Object.values(mapping)
                .filter((mappedSchool: { sisId: string }) => !!mappedSchool.sisId)
                .map((mappedSchool: { nid: string }) => mappedSchool.nid);
              setAllMappedSchoolsLength(
                navSchools
                  .filter((navSchool) => mappedSchools.includes(navSchool.nid))
                  .sort((a, b) => (a.isAccount > b.isAccount ? 1 : a.isAccount < b.isAccount ? -1 : 0)).length || 0,
              );
            }
          } catch (error) {
            showNotification(
              NotificationTypes.error,
              'Error Getting School Mappings',
              'Failure in getting data from server.',
            );
          }
        }
        setDataType(data.dataType || 'Student Records');
      }
      if (isMountedRef.current) setRunningData(data);
      const configCheck = !data.connection?.configured || !data.connection?.working;
      const schoolCheck = !data.schoolsMapped && props.includeSchools;
      const runningJobs = data.isRunning;

      const jobEnabled = !data?.isDisabled && data.cron !== 'ONE_TIME_IMPORT';
      const failedChecks = configCheck || schoolCheck || runningJobs;

      if (isMountedRef.current) setDisableStart(jobEnabled || failedChecks);
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting SIS Import Settings',
        'Failure in getting data from server.',
      );
    } finally {
      if (isMountedRef.current) {
        setLoading(false);
        setStartBtnLoading(false);
        setHideFooterComp(false);
        const { data } = await apiClient.get(`/data-ingest/sis/tenant-meta`);

        if ((isCleverIntegration && data.sisIntegration !== 'clever') || (!isCleverIntegration && (data.sisIntegration === 'clever'))) {
          setDisableStart(true);
        }

      }
    }
  };

  const disableImport = async () => {
    try {
      setIsDisabling(true);
      await apiClient.patch(`/data-ingest/ingress/${type}/disable`, {
        isDisabled: true,
      });
      await fetchIngressAndJobs();
      setIsDisabling(false);
      showNotification(NotificationTypes.success, `Successfully Disabled ${dataType}`, '');
      return;
    } catch (error) {
      showNotification(NotificationTypes.error, `Error Disabling ${dataType}`, 'Server Error');
    }
  };

  React.useEffect(() => {
    const init = async () => {
      isMountedRef.current = true;
      if (pluginDetails?.type && pluginDetails?.type === PluginStatus.NotSupported) {
        setIsPluginDeprecated(true);
        setIsWarning(false);
      }
      if (pluginDetails?.message) {
        setUpdateMessage(pluginDetails.message);
      }
      void fetchIngressAndJobs();
    };
    init();
    return () => (isMountedRef.current = false);
  }, []);

  return (
    <>
      <Wizard {...props} saveAndCloseFn={props.cancelFn} hideHeaderButtons={true} hideFooter={disableFooter}>
        <Row>
          <Space direction="vertical">
            {updateMessage && (
              <Col span="16">
                <Alert message={updateMessage} type={isWarning ? 'warning' : 'error'} showIcon />
              </Col>
            )}
            {startBtnLoading || runningData.isRunning || runningData.isDisabled === false ? (
              <>
                {(runningData.isRunning || runningData.isDisabled === false) && (
                  <>
                    <Col span="16">
                      <span className="pagepara">
                        <b>This import has already been started.</b> If you have completed the previous steps, you can
                        disable this import or start a new one. The import may take a few minutes to complete.
                      </span>
                    </Col>
                    <br />
                  </>
                )}
              </>
            ) : (
              <>
                <Col span="16" data-test-id="import-info-txt">
                  <span className="pagepara">
                    <b>This import has not been started yet.</b> If you have completed the previous steps, you can run a
                    test import or start an import now. The import may take a few minutes to complete.
                  </span>
                </Col>
                <br />
              </>
            )}
            <Col span="24">
              {!startBtnLoading && !runningData.isRunning && runningData.isDisabled === true && (
                <Button
                  className="button button-blue"
                  onClick={() => askImportConfirmation(true, enableAskImportPopUp)}
                  disabled={disableStart || startBtnLoading || isPluginDeprecated}
                  style={{ marginRight: '5px' }}
                  data-test-id="test-import-btn"
                >
                  Test Import
                </Button>
              )}
              {(startBtnLoading || runningData.isRunning || runningData.isDisabled === true) && (
                <Button
                  loading={startBtnLoading}
                  className="button"
                  onClick={() => askImportConfirmation(false, enableAskImportPopUp)}
                  disabled={disableStart || isPluginDeprecated || isLegacyCleverEnabled}
                  data-test-id="start-import-btn"
                  title={isLegacyCleverEnabled ? StatusMessages.LEGACY_CLEVER_ENABLED : ''}
                >
                  {startBtnLoading
                    ? 'Loading...'
                    : runningData.isRunning || !runningData.isDisabled
                    ? (!runningData.lastJob || runningData.lastJob?.done ? 'Test ' : '') + 'Import in progress'
                    : 'Start Import'}
                </Button>
              )}
              {!runningData.isRunning && runningData.isDisabled === false && (
                <>
                  <Button
                    loading={startBtnLoading}
                    className="button button-blue"
                    onClick={() => disableImport()}
                    disabled={isDisabling}
                    style={{ marginRight: '10px' }}
                    data-test-id="disable-import-btn"
                  >
                    Disable Import
                  </Button>
                  {isDisabling && (
                    <span className="pagepara">
                      <Spin size="small"></Spin> Disabling import
                    </span>
                  )}
                </>
              )}
              {/* <span style={{ marginLeft: '20px' }}>
                  {startBtnLoading? 'Loading latest import data' : runningData.isRunning ? 'Import in progress' : runningData.lastJob && `Last Import Completed on ${new Date(runningData.lastJob.end).toLocaleString()}`}
                </span> */}
            </Col>
            <br />
            <Col span="24">
              <Title level={3} data-test-id="import-summary-title-txt">
                Import Summary
              </Title>
            </Col>
            <Col span="24">
              <Title level={4} data-test-id="data-type-title-txt">
                Data Type
              </Title>
              <span className="pagepara" data-test-id="data-type-info-txt">
                {props.typeLabel ? props.typeLabel : 'Student Records'}
              </span>
            </Col>
            <br />
            {props.includeSchools && (
              <>
                <Col span="24">
                  <Title level={4}>Available At</Title>
                  <span className="pagepara">
                    {loading ? (
                      <Spin size="small"></Spin>
                    ) : schools.length ? (
                      `${schools.length} Selected Schools`
                    ) : (
                      `All Mapped Schools (${allMappedSchoolsLength})`
                    )}
                  </span>
                </Col>
                <br />
              </>
            )}
            <Col span="24">
              <Title level={4} data-test-id="frequency-title-txt">
                Update Frequency
              </Title>
              <span className="pagepara" data-test-id="frequency-info-txt">
                {loading ? <Spin size="small"></Spin> : frequency}
              </span>
            </Col>
          </Space>
        </Row>
      </Wizard>
      <Modal
        visible={askConfirmationModal}
        onOk={onAskOk}
        onCancel={onAskCancel}
        cancelButtonProps={{ hidden: false }}
        wrapClassName="start_import_modal"
        okText="Proceed with import"
        cancelText="Cancel Import"
      >
        <Title level={5}>Are you sure you want to start an actual import?</Title>
      </Modal>
      <Modal
        visible={showConfirmationModal}
        onOk={onOk}
        cancelButtonProps={{ hidden: true }}
        wrapClassName="start_import_modal"
        okText="Done"
      >
        <Title level={5}>Your import has started!</Title>
        <ul>
          <li>
            Click <b>Done</b> to view your import status on the PowerSchool Data Integration page.
          </li>
          <li>The import may take a few minutes to complete.</li>
        </ul>
      </Modal>
    </>
  );
};

export default SisStartImport;
