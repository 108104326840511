import * as React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';
import DiplomaTypeCodeMapping from '../../components-v2/SisCodeMatching/diplomaType';
import FerpaBlockCodeMapping from '../../components-v2/SisCodeMatching/ferpaBlock';

const defaultDiplomaTypeMapping: Record<string, (string | number)[]> = {
  'Endorsed/Advanced Diploma': [],
  'General Education Development Credential(GED)': [],
  'High School Equivalency Credential(other than GED)': [],
  'International Baccalaureate': [],
  'Modified Diploma': [],
  'Regular Diploma': [],
  'Vocational Certificate': [],
  Other: [],
};
const defaultFerpaBlockMapping: Record<string, (string | number)[]> = {
  Y: [],
  N: [],
};

const SisAlumniDefineCodes = (props: WizardProps): React.ReactElement => {
  const [parameterGroup, setParameterGroup] = React.useState({});
  const [translateConfig, setTranslateConfig] = React.useState({
    valueMappings: { diplomaTypeCodes: {}, ferpaBlockCodes: {} },
    headerMappings: {},
  });
  const [importRulesConfig, setImportRulesConfig] = React.useState(null);
  const [diplomaTypeMapping, setDiplomaTypeMapping] = React.useState(defaultDiplomaTypeMapping);
  const [ferpaBlockMapping, setFerpaBlockMapping] = React.useState(defaultFerpaBlockMapping);
  const [showDiplomaType, setShowDiplomaType] = React.useState(false);
  const [showFerpaBlock, setShowFerpaBlock] = React.useState(false);
  const [blankFerpaValueAsNo, setBlankFerpaValueAsNo] = React.useState(true);
  const [sisIntegration, setSisIntegration] = React.useState<string>('psSis');

  const saveMapping = async (): Promise<boolean> => {
    const config = {
      ...translateConfig,
      valueMappings: {
        ...translateConfig.valueMappings,
        ferpaBlockCodes: ferpaBlockMapping,
        diplomaTypeCodes: diplomaTypeMapping,
      },
    };
    const importRules = {
      ...importRulesConfig,
      blankFerpaValueAsNo,
    };
    if (Object.keys(config.headerMappings).length <= 5) {
      showNotification(NotificationTypes.error, 'Error Saving Mappings', 'Something went wrong.');
      return false;
    }
    const { data } = await apiClient.patch('data-ingest/sis/parameter-group/alumnitranslateConfig', {
      ...parameterGroup,
      translateConfig: config,
      importRules,
    });

    setTranslateConfig(data.translateConfig);

    if (data.translateConfig && data.translateConfig.valueMappings?.ferpaBlockCodes) {
      setFerpaBlockMapping(data.translateConfig.valueMappings.ferpaBlockCodes);
    }
    if (data.translateConfig && data.translateConfig.valueMappings?.diplomaTypeCodes) {
      setDiplomaTypeMapping(data.translateConfig.valueMappings.diplomaTypeCodes);
    }
    return true;
  };

  const getMappings = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/alumnitranslateConfig');
      const meta = await apiClient.get(`/data-ingest/sis/tenant-meta`);
      setParameterGroup(data);
      const { translateConfig = {}, importRules = {} } = data;
      const { valueMappings } = translateConfig;

      setTranslateConfig(translateConfig);
      setImportRulesConfig(importRules);
      setShowDiplomaType(true);
      setSisIntegration(meta?.data?.sisIntegration || 'psSis');
      if (meta?.data?.sisIntegration !== 'eSP') {
        setShowFerpaBlock(true);
      }

      if (valueMappings?.ferpaBlockCodes) {
        setFerpaBlockMapping(valueMappings.ferpaBlockCodes);
      }
      if (valueMappings?.diplomaTypeCodes) {
        setDiplomaTypeMapping(valueMappings.diplomaTypeCodes);
      }
      if (importRules?.blankFerpaValueAsNo !== undefined) {
        setBlankFerpaValueAsNo(importRules.blankFerpaValueAsNo);
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mappings', 'Failure in getting data from server.');
    }
  };

  return (
    <SisDefineCodes
      codesToDefine={[
        showDiplomaType && (
          <DiplomaTypeCodeMapping
            key="define-diploma"
            diplomaTypeMapping={diplomaTypeMapping}
            setDiplomaTypeMapping={setDiplomaTypeMapping}
            headerMappings={translateConfig?.headerMappings}
            sisIntegration={sisIntegration}
          />
        ),
        showFerpaBlock && (
          <FerpaBlockCodeMapping
            key="define-ferpa"
            ferpaBlockMapping={ferpaBlockMapping}
            setFerpaBlockMapping={setFerpaBlockMapping}
            blankFerpaValueAsNo={blankFerpaValueAsNo}
            setBlankFerpaValueAsNo={setBlankFerpaValueAsNo}
          />
        ),
      ].filter((code) => !!code)}
      saveMapping={saveMapping}
      getMappings={getMappings}
      {...props}
    />
  );
};

export default SisAlumniDefineCodes;
