import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { FrequencyOptions } from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';

const SisAlumniSyncSettings = (props: WizardProps): React.ReactElement => {
  const frequencies: FrequencyOptions = [
    {
      value: 'ONE_TIME_IMPORT',
      label: 'One-Time Import',
    },
    {
      value: 'ALUMNI_TRACKER',
      label: 'Alumni Tracker Dates(Apr 1, Aug 1, Nov 1)',
    },
  ];

  const defaultFrequency = 'ONE_TIME_IMPORT';
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(true);
  const [doNotOverwriteEmail, setDoNotOverwriteEmail] = useState(true);

  return (
    <SisSyncSettings
      frequencies={frequencies}
      defaultFrequency={defaultFrequency}
      shouldInactivateRecords={shouldInactivateRecords}
      overwriteNavData={overwriteNavData}
      doNotOverwriteEmail={doNotOverwriteEmail}
      flagNames={['shouldInactivateRecords', 'overwriteNavData', 'doNotOverwriteEmail']} //need to be in same order with flag setter
      setFlagCallees={[setShouldInactivateRecords, setOverwriteNavData, setDoNotOverwriteEmail]} //need to be in same order with flag setter
      type="alumni"
      showRadio={false}
      includeSchools={true}
      includeInactivateRule={true}
      includeOverwriteRule={true}
      includeOverwriteEmailRule={true}
      fetchAvailableSchoolMappings={true}
      {...props}
    />
  );
};

export default SisAlumniSyncSettings;
