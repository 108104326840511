import * as React from 'react';
import { Col, Table, Select, Tooltip} from 'antd';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import { AvailableCodes, StaffUserType } from '../../utils/constants';
import apiClient from '../../utils/apiClient';
import { availableSisCodes, changeMapping } from '../../utils/utils';
import _ from 'lodash';
import { QuestionCircleFilled } from '@ant-design/icons';
import { IntegrationContext } from '../../utils/context';

interface StaffRoleProps {
  roleMapping: Record<string, (string | number)[]>;
  setRoleMapping: (setRoleMapping: Record<string, (string | number)[]>) => void;
  staffUserType: StaffUserType;
  sisStaffRoles: {
    label: string;
    value: string;
  }[];
  setNavRolesMap: (setNavRolesMap: any) => void;
}

const StaffRole = ({
  roleMapping,
  setRoleMapping,
  setNavRolesMap,
  staffUserType,
  sisStaffRoles,
}: StaffRoleProps): React.ReactElement => {
  const isMountedRef = React.useRef(null);
  const { Option } = Select;
  const [loading, setLoading] = React.useState(false);
  const [navDefaultRoles, setNavDefaultRoles] = React.useState([]);
  const [navRoles, setNavRoles] = React.useState([]);
  const [staffUsedRoles, setStaffUsedRoles] = React.useState([]);
  const { districtId } = React.useContext(IntegrationContext);
  const toBeMovedandDisabled = ["Teacher (D)"];
  const valueToBeDefaultInDropDown = ["Teacher"];
  const textForAutoMapping= "This role will be automatically mapped from Powerschool SIS";

  const availableNavRoles = (): AvailableCodes[] =>
    availableSisCodes<AvailableCodes>({}, navDefaultRoles);

  const availableSisRoles = (): AvailableCodes[] => availableSisCodes<AvailableCodes>(roleMapping, sisStaffRoles);

  const getNavianceRolesMap = (data): any => {
    const navRolesMap = {};
    data.forEach((role) => {
      if (navRolesMap[role.name]) {
        navRolesMap[role.name] = [
          ...navRolesMap[role.name],
          {
            institutionId: role.institutionId,
            id: role.id,
          },
        ]
      } else {
        navRolesMap[role.name] = [
          {
            institutionId: role.institutionId,
            id: role.id,
          },
        ]
      }
    });
    return navRolesMap;
  };

  const getNavianceRolesList = async () => {
    try {
      setLoading(true);
      let { data } = await apiClient.get(`/roles/userroles?userType=${staffUserType}`);

      // Fix for not showing roles for inactive school or schools with no account
      if (staffUserType === StaffUserType.school) {
        const navSchools = await apiClient.get('/highschools/v2?byType=district&includeInactive=false');
        const activeNavSchools = navSchools.data.map(navSchool => navSchool.nid);
        activeNavSchools.push(districtId);
        data = data.filter(record => activeNavSchools.includes(record.institutionId));
      }

      const navRolesMap = getNavianceRolesMap(data);
      for (const key in roleMapping) {
        if (!navRolesMap[key]) {
          delete roleMapping[key];
        }
      }
      setRoleMapping(roleMapping);
      setNavRolesMap(navRolesMap);
      const navianceRoles = Object.keys(navRolesMap).map((key) => {
        return {
          label: key,
          value: key,
        };
      });
      const newMapping = moveOnTop(navianceRoles,toBeMovedandDisabled);
      changeDisabledMapping();
      setNavDefaultRoles(newMapping);
      setLoading(false);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, `Error Getting ${staffUserType} Level Roles`, 'Failure in getting data from server.');
    }
  };

  const moveOnTop = (mapping, toBeMoved) => {
    const newMapp = [];
    const movedMapping = {};
    mapping.map((role,index) => {
     const label = role.label;
     if(toBeMoved.includes(label)){
      movedMapping[label] = role
      delete mapping[index];
     }
    });
    toBeMoved.map((role)=>{
      if(movedMapping[role]!=null){
        newMapp.push(movedMapping[role])
      }
    })
    return newMapp.concat(mapping);
  };

  const changeDisabledMapping = ()=>{
    toBeMovedandDisabled.map((role,index) => {
      changeMapping(role,[valueToBeDefaultInDropDown[index]],roleMapping, setRoleMapping);
    })
  }

  React.useEffect(() => {
    setNavRoles(availableNavRoles());
  }, [navDefaultRoles]);

  React.useEffect(() => {
    setStaffUsedRoles(availableSisRoles());
  }, [roleMapping, sisStaffRoles]);

  React.useEffect(() => {
    isMountedRef.current = true;
    getNavianceRolesList();
    return () => (isMountedRef.current = false);
  }, []);

  const roleTableColumns = [
    {
      title: 'Naviance Field: Role',
      width: '33%',
      render: (data) => (
        <>
          {data.label}
          {toBeMovedandDisabled.includes(data.label)  && (
            <Tooltip title={textForAutoMapping}>
              <span>
                {'  '}<QuestionCircleFilled />
              </span>
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: 'SIS Code(s): RoleDef.Name',
      width: '33%',
      render: (data) => (
        <Select
          className="select select-responsive"
          allowClear
          mode="multiple"
          data-test-id={`${staffUserType}_role_select_${data.value}`}
          value={roleMapping[data.value]}
          onChange={(val) => changeMapping(data.value, val, roleMapping, setRoleMapping)}
          disabled={toBeMovedandDisabled.includes(data.value)}
        >
          {staffUsedRoles.map((role, ind) => (
            <Option
              className="selectOptions"
              key={`${staffUserType}-role-${data.value}-${role.value}-${ind}`}
              value={role.value}
              data-test-id={`${staffUserType}_role_option_${role.label}`}
              disabled={role.used && !roleMapping[data.value]?.includes(role.value)}
            >
              {role.label}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  return (
    <>
      <Col span={18}>
        <h2 className="infoHeading uploadHeading">Role</h2>
        Match the Role in Naviance to the SIS code(s) from <b>RoleDef.Name</b><br></br>
        If matching multiple SIS codes to a Naviance field, then separate with commas.

      </Col>
      <Col span={18}>
        <Table columns={roleTableColumns} dataSource={navRoles} pagination={false} loading={loading} rowKey="code" />
      </Col>
      <br/>
    </>
  );
};

export default StaffRole;