import type { SisCredentialResponse } from '../../../../../../libs/common-interfaces';
import { Space, Button } from 'antd';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import * as React from 'react';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import Col from '../../components-v2/Col';
import Row from '../../components-v2/Row';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { featureFlags } from '../../utils/featureFlags';
import { IntegrationContext } from '../../utils/context';
import PencilIcon from '../../../assets/Icon/PencilIcon';
import Modal from 'antd/lib/modal/Modal';
import { API_URL } from '../../utils/constants';

interface SisCredentialsProps {
  credentials: {
    host: string;
    clientId: string;
    discoveryUrl?: string;
    cleverId?: string;
  };
  sisIntegration: string;
  sisIntegrationMeta: string;
  hostMeta: string;
  save: (client: SisCredentialResponse) => void;
  disableLegacyClever: () => void;
  close: () => void;
}

const NotificationStyle = {
  backgroundColor: '#D9FCF1',
  borderLeft: 'inset',
  borderLeftColor: '#12D097',
};

const editStyle = {
  fontSize: '1.1rem',
  color: 'rgb(20,20,151)',
};

const SisCredentials = (props: SisCredentialsProps): React.ReactElement => {
  const { sisIntegration, sisIntegrationMeta, hostMeta } = props;

  const [host, setHost] = React.useState(props.credentials.host);
  const [clientId, setClientId] = React.useState(props.credentials.clientId);
  const [discoveryUrl, setDiscoveryUrl] = React.useState(props.credentials.discoveryUrl);
  const [cleverId, setCleverId] = React.useState(props.credentials.cleverId);
  const [clientSecret, setClientSecret] = React.useState('');
  const [label, setLabel] = React.useState('PowerSchool');
  const [invalidInputs, setInvalidInputs] = React.useState([]);
  const [disableClientSecret, setDisableClientSecret] = React.useState(false);
  const [isCleverIDEditable, setIsCleverIDEditable] = React.useState(true);
  const { isCleverIntegration } = React.useContext(IntegrationContext);
  const [showModal, setShowModal] = React.useState(false);
  const [resetStatus, setResetStatus] = React.useState(false);
  const [drawerLoading, setDrawerLoading] = React.useState(false);
  const [isDataChanged, setIsDataChanged] = React.useState(true);
  const hostRef = React.useRef(null);
  const clientIdRef = React.useRef(null);
  const cleverIdRef = React.useRef(null);
  const secretPlaceholder = '**********';

  React.useEffect(() => {
    if (cleverId) {
      setIsCleverIDEditable(false);
    }
  }, []);

  React.useEffect(() => {
    if (sisIntegration === 'eSP') {
      setLabel('eSchoolPlus');
    } else {
      setLabel('PowerSchool');
    }
    setHost(props.credentials.host);
    setDiscoveryUrl(props.credentials.discoveryUrl);
    setClientId(props.credentials.clientId);
    if (clientId) {
      setClientSecret(secretPlaceholder);
      setDisableClientSecret(true);
      setIsDataChanged(false);
    }
    setInvalidInputs([]);
  }, [sisIntegration]);

  const setCredentials = async () => {
    const sisIntegrationCheck = isCleverIntegration ? 'clever' : sisIntegration;
    if (hostMeta !== '' && !resetStatus && sisIntegrationCheck !== sisIntegrationMeta) {
      showNotification(NotificationTypes.info, 'Info', 'Please Reset Your Configuration');
      return;
    }

    try {
      setInvalidInputs([]);
      let patchBody = {};
      let isCleverIdUnique = true;

      if (isCleverIntegration) {
        patchBody = {
          cleverId: cleverId,
          sisIntegration: 'clever',
        };
        const { data: uniqueness } = await apiClient.get(`/district/check-unique-clever-id?cleverId=${cleverId}`);
        isCleverIdUnique = uniqueness.isUnique;
      } else {
        patchBody = {
          // Strip protocol to avoid triggering RFI vulnerability in WAF.
          host: host.replace('https://', ''),
          clientId,
          clientSecret,
          discoveryUrl: discoveryUrl ? discoveryUrl?.replace('https://', '') : undefined,
          sisIntegration,
        };
        setClientSecret(secretPlaceholder);
      }

      if (isCleverIdUnique) {
        const { data } = await apiClient.patch('data-ingest/sis/tenant-meta', patchBody);

        if (isCleverIntegration) {
          const cleverDetails = {
            cleverId,
          };

          await apiClient.patch('/district/update-clever-id', cleverDetails);
          if (featureFlags['feature.dataIngest.auto.disableLegacyClever']) {
            props.disableLegacyClever();
          }
        }
        //TODO: componentize the success style
        showNotification(
          NotificationTypes.success,
          <Title level={5}>{isCleverIntegration ? 'Clever ID' : 'Credentials'} Saved</Title>,
          <Paragraph type="secondary">{new Date().toLocaleString()}</Paragraph>,
          NotificationStyle,
          <CheckCircleFilled style={{ color: '#12D097' }} />,
        );

        let saveData = {
          host: host,
          clientId: clientId,
          discoveryUrl,
          sisIntegration: sisIntegration,
        };

        if (isCleverIntegration) {
          saveData = data;
        }

        props.save(saveData);
        props.close();
      } else {
        showNotification(NotificationTypes.error, 'Error Connecting', 'Duplicate Clever ID');
      }
    } catch (err) {
      const { response } = err;
      if (response && response.data && response.status === 400) {
        console.error(response.data);
        let errorMessage = response.data.message;
        switch (response.data.message) {
          case 'Check your credentials and try again.':
            hostRef.current.focus();
            setInvalidInputs(['host', 'clientId', 'clientSecret']);
            break;
          case 'Check your Client ID and/or Client Secret and try again.':
            clientIdRef.current.focus();
            setInvalidInputs(['clientId', 'clientSecret']);
            break;
          case 'This is not a valid PowerSchool Domain. Check the formatting and try again.':
            hostRef.current.focus();
            setInvalidInputs(['host']);
            break;
          case 'No Clever Registration':
            cleverIdRef.current.focus();
            setInvalidInputs(['cleverId']);
            errorMessage = 'Please enter valid Clever ID';
            break;
          default:
            setInvalidInputs([]);
        }
        showNotification(NotificationTypes.error, 'Error Connecting', errorMessage);
      } else {
        console.error(err);
        showNotification(NotificationTypes.error, 'Error', 'Error saving credentials');
      }
    }
  };
  const canSave = (): boolean => {
    if (isCleverIntegration) {
      return !(cleverId && cleverId !== '');
    }
    return !(
      host &&
      host !== '' &&
      clientId &&
      clientId !== '' &&
      clientSecret &&
      clientSecret !== '' &&
      isDataChanged &&
      (sisIntegration !== 'eSP' || (discoveryUrl && discoveryUrl !== ''))
    );
  };

  const downloadPlugin = async () => {
    try {
      console.log(
        "featureFlags['feature.dataIngest.student.studentCounselorTable']",
        featureFlags['feature.dataIngest.student.studentCounselorTable'],
      );
      const { data: url } = await apiClient.get('/data-ingest/sis/plugin', {
        headers: { accept: 'application/zip' },
        params: {
          sisVersionFeatureEnabled: featureFlags['feature.dataIngest.student.studentCounselorTable'],
        }
      });

      const fileName = 'naviance.zip';
      // const url = URL.createObjectURL(data);
      
      // window.location.href = `${API_URL}/data-ingest/sis/plugin?sisVersionFeatureEnabled=${featureFlags['feature.dataIngest.student.studentCounselorTable']}`
      const a = document.createElement('a');
      document.body.appendChild(a);
      a.setAttribute('style', 'display: none');
      a.href = url`&response-content-disposition=attachment;filename=${encodeURIComponent(fileName)}`;
      a.download = fileName;
      a.click();

      // window.URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error', 'Download not successful');
    }
  };

  const onClickResetConfig = () => {
    setShowModal(true);
  };

  const onResetCancel = () => {
    setShowModal(false);
  };

  const restConfigOk = async () => {
    setDrawerLoading(true);
    try {
      // reset-config endpoint will delete all ingress,channels and parametergroup associated with the tenant.
      const { data } = await apiClient.delete('/data-ingest/reset-config');
      
      // if reest is successfull then reset UI fields
      if (data) {
        setHost("");
        setClientId("");
        setDiscoveryUrl("");
        setCleverId("");
        setClientSecret("");
        setDisableClientSecret(false);
      }
      showNotification(NotificationTypes.success, 'Success', 'Mapping updated successfully');
    } catch (error) {
      showNotification(NotificationTypes.error, 'Error Resetting Configuration', 'Failure in saving data on server.');
    }
    setDrawerLoading(false);
    setShowModal(false);
    setResetStatus(true);
  };

  const resetConfigDiv = featureFlags['feature.dataIngest.student.resetConfigurationMapping'] && (
    <Row>
      <Col span={24}>
        <p className="drawerLableText drawerTopGutter">Reset Current SIS Configuration</p>
      </Col>
      <Col className="lineTopGutter" span={24}>
        <Space align="center">
          <p style={{ color: '#656567' }}>
            If you currently have a Clever or Powerschool Data Integration set up in Naviance, you must click
            <b> Reset Configuration </b> before continuing with the setup to ensure a successful data import.
            <div className="lineTopGutter">
              Your existing Naviance data will not be deleted, but you automated sync will be disabled.
            </div>
            <a onClick={onClickResetConfig} style={{ textDecoration: 'underline' }}>
              Reset Configuration.
            </a>
          </p>
        </Space>
      </Col>
    </Row>
  );

  return (
    <>
      {!isCleverIntegration && (
        <>
          {sisIntegration === 'psSis' && (
            <Row>
              <Col span={24}>
                <Title className="drawerSubTitle" level={2} data-test-id="drawer_subtitle">
                  PowerSchool SIS Configuration File
                </Title>
              </Col>
              <Col span={24} className="drawerLinkContainer">
                <p className="drawerDescription">PowerSchool SIS Plugin:</p>
                <a href={`${API_URL}/data-ingest/sis/plugin?sisVersionFeatureEnabled=${featureFlags['feature.dataIngest.student.studentCounselorTable']}`} download={'naviance.zip'} data-test-id="plugin_download_link">
                  Download Configuration File
                </a>
              </Col>
            </Row>
          )}
          <Row align="middle">
            <Col span={16}>
              <p className="drawerLableText" data-test-id="input_host_label">
                {label} {sisIntegration !== 'eSP' ? 'SIS Domain' : 'API URL'}
              </p>
            </Col>
            <Col span={24}>
              <input
                className={`drawerInput drawerInputFull ${invalidInputs.includes('host') ? 'drawerInvalidInput' : ''}`}
                type="text"
                value={host}
                ref={hostRef}
                placeholder={
                  sisIntegration === 'eSP'
                    ? `Enter ${label} SIS Domain (e.g. https://<your school>.${label}.powerschool.com)`
                    : `Copy URL from URL bar in ${label} SIS`
                }
                onChange={(e) => {setIsDataChanged(true); setHost(e.target.value)}}
                data-test-id="input_host"
              />
              {invalidInputs.includes('host') && <ExclamationCircleFilled className="inputErrorIcon" />}
              {sisIntegration !== 'eSP' &&
                `Expected format is "https://yourdomain.powerschool.com"
              or "https://ps.yourdomain.edu".`}
            </Col>
          </Row>
          {sisIntegration === 'eSP' && (
            <Row align="middle" className="drawerTopGutter">
              <Col span={16}>
                <p className="drawerLableText" data-test-id="input_eSPDiscoveryUrl_label">
                  OIDC Discovery URL
                </p>
              </Col>
              <Col span={24}>
                <input
                  className="drawerInput drawerInputFull"
                  type="text"
                  value={discoveryUrl}
                  placeholder="Enter OIDC Discovery URL"
                  onChange={(e) => {setIsDataChanged(true); setDiscoveryUrl(e.target.value)}}
                  data-test-id="input_eSPDiscoveryUrl"
                />
              </Col>
            </Row>
          )}
          <Row align="middle" className="drawerTopGutter">
            <Col span={9}>
              <p className="drawerLableText" data-test-id="input_clientId_label">
                Client ID
              </p>
            </Col>
            <Col span={24}>
              <input
                className={`drawerInput drawerInputFull ${
                  invalidInputs.includes('clientId') ? 'drawerInvalidInput' : ''
                }`}
                type="text"
                placeholder="Enter Client ID"
                value={clientId}
                ref={clientIdRef}
                onChange={(e) => {setIsDataChanged(true); setClientId(e.target.value)}}
                data-test-id="input_clientId"
              />
              {invalidInputs.includes('clientId') && <ExclamationCircleFilled className="inputErrorIcon" />}
            </Col>
          </Row>
          <Row align="middle" className="drawerTopGutter">
            <Col span={9}>
              <p className="drawerLableText" data-test-id="input_clientSecret_label">
                Client Secret
              </p>
            </Col>
            <Col span={16}>
              <input
                className={`drawerInput drawerInputFull ${
                  invalidInputs.includes('clientSecret') ? 'drawerInvalidInput' : ''
                }`}
                type="password"
                value={clientSecret}
                disabled={disableClientSecret}
                placeholder="Enter Client Secret"
                onChange={(e) => {setIsDataChanged(true); setClientSecret(e.target.value)}}
                data-test-id="input_clientSecret"
              />
              {invalidInputs.includes('clientSecret') && <ExclamationCircleFilled className="inputErrorIcon" />}
            </Col>
            <Col span={1}></Col>
            <Col span={6}>
              <Button
                type="link"
                disabled={!disableClientSecret}
                onClick={() => setDisableClientSecret(false)}
                data-test-id="button_change"
              >
                Change
              </Button>
            </Col>
          </Row>

          {resetConfigDiv}

          <Row>
            <Col className="drawerTopGutter" span={24}>
              <Space align="center">
                <button
                  className="buttonSolid"
                  onClick={() => setCredentials()}
                  data-test-id="save_creds_btn"
                  disabled={canSave()}
                >
                  Test and Save
                </button>
              </Space>
            </Col>
          </Row>
        </>
      )}
      {isCleverIntegration && (
        <>
          <Row align="middle" className="drawerTopGutter">
            <Col span={9}>
              <p className="drawerLableText" data-test-id="input_cleverId_label">
                Clever ID
              </p>
            </Col>
            <Col span={24}>
              {!isCleverIDEditable && (
                <>
                  <b>{cleverId}</b>
                  <Button type="link" onClick={() => setIsCleverIDEditable(true)} data-test-id="clever_id_edit">
                    <sub>
                      <PencilIcon style={{ marginRight: '0px' }} />
                    </sub>
                    <span style={editStyle}>Edit</span>
                  </Button>
                </>
              )}
              {isCleverIDEditable && (
                <input
                  className={`drawerInput drawerInputFull ${
                    invalidInputs.includes('cleverId') ? 'drawerInvalidInput' : ''
                  }`}
                  type="text"
                  placeholder="Enter Clever ID"
                  value={cleverId}
                  ref={cleverIdRef}
                  onChange={(e) => setCleverId(e.target.value)}
                  data-test-id="input_cleverId"
                />
              )}
              {invalidInputs.includes('cleverId') && <ExclamationCircleFilled className="inputErrorIcon" />}
            </Col>
          </Row>
          {resetConfigDiv}
          <Row></Row>
          {isCleverIDEditable && (
            <Row>
              <Col className="drawerTopGutter" span={4}>
                <Space align="center">
                  <button
                    className="buttonSolid"
                    onClick={() => setCredentials()}
                    data-test-id="save_creds_btn"
                    disabled={canSave()}
                  >
                    Save
                  </button>
                </Space>
              </Col>
              <Col className="drawerTopGutter" span={6}>
                <Space align="center">
                  <button className="button" onClick={() => props.close()} data-test-id="cancel_creds_btn">
                    Cancel
                  </button>
                </Space>
              </Col>
            </Row>
          )}
        </>
      )}

      <Modal
        visible={showModal}
        onCancel={() => onResetCancel()}
        okText="Reset Configuration"
        onOk={() => {
          restConfigOk();
        }}
        cancelText="Cancel"
        className="modal"
        confirmLoading={drawerLoading}
        style={{ marginTop: '15%', position: 'revert', padding: '0px' }}
      >
        <Title level={5}>Reset Current Configuration?</Title>
        <body>
          {!isCleverIntegration ? (
            <span>
              {' '}
              Data currently in Naviance will remain but auto-sync will be disabled. The new SIS will need to be mapped
              in <b>SIS Import Settings</b>.
            </span>
          ) : (
            <span>
              {' '}
              Data currently in Naviance will remain but auto-sync will be disabled. The new Clever will need to be
              mapped in <b>SIS Import Settings</b>.
            </span>
          )}
        </body>
      </Modal>
    </>
  );
};

export default SisCredentials;
