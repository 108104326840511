import * as React from 'react';
import { Col, Row, Select } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

import type { FrequencyDefinition, FrequencyNames } from '../../../../../../libs/common-interfaces';

const { Option } = Select;

export type FrequencyOptions = FrequencyOption[];

export interface FrequencyOption {
  value: FrequencyNames;
  label: string;
  info?: string;
  compatibleWith?: FrequencyNames[];
}

const DaysOfWeeks = [
  { label: 'Monday', value: 2 },
  { label: 'Tuesday', value: 3 },
  { label: 'Wednesday', value: 4 },
  { label: 'Thursday', value: 5 },
  { label: 'Friday', value: 6 },
  { label: 'Saturday', value: 7 },
  { label: 'Sunday', value: 1 },
];

interface DataUpdateFrequencyProps {
  frequency: FrequencyDefinition;
  setFrequency: (frequency) => void;
  frequencies: FrequencyOptions;
}

export const DataUpdateFrequency = ({ frequency, setFrequency, frequencies }: DataUpdateFrequencyProps) => {
  const [selectedOption, setSelectedOption] = React.useState(frequency.meta?.day);

  function onChangeFrequency(name: FrequencyNames) {
    setFrequency({
      name,
    });
  }

  const selectedFrequency: FrequencyOption = frequencies.find(
    (f) => f.value === frequency.name || f.compatibleWith?.indexOf(frequency.name) >= 0,
  );

  function handleChange(value, selectOptionSetter) {
    /* istanbul ignore next */
    if (selectedFrequency.value === 'WEEKLY') {
      if (frequency.meta) {
        frequency.meta.day = value;
      } else {
        setFrequency({
          name: selectedFrequency.value,
          meta: {
            day: value,
          },
        });
      }
    }
    selectOptionSetter(value);
  }

  return (
    <Row className="mainSelectRow" gutter={16}>
      <Col span={24}>
        <h2 className="infoHeading uploadHeading">Data Update Frequency</h2>
      </Col>
      <Col span={8}>
        <Select
          placeholder="Select Import Type"
          className="select"
          onChange={onChangeFrequency}
          value={selectedFrequency?.value}
          data-test-id={`freq_select`}
        >
          {frequencies.map((freq) => {
            return (
              <Option
                className="selectOptions"
                key={freq.value}
                value={freq.value}
                data-test-id={`freq_option_${freq.value}`}
              >
                {freq.label}
              </Option>
            );
          })}
        </Select>
      </Col>
      {selectedFrequency?.info && (
        <Col span={24}>
          <Row gutter={6} style={{ marginTop: '0.5rem' }}>
            <Col>
              <InfoCircleOutlined size={0.5} />
            </Col>
            <Col data-test-id={`update_freq_info`}>
              <small>{selectedFrequency.info}</small>
            </Col>
          </Row>
        </Col>
      )}
      {selectedFrequency?.value === 'WEEKLY' && (
        <div style={{ marginTop: '20px', width: '33.5%' }}>
          <Col span={24}>
            <h2 className="infoHeading uploadHeading">Day of the week</h2>
          </Col>
          <Col span={24}>
            <Select
              placeholder="Select Day"
              className="select"
              onChange={(e) => handleChange(e, setSelectedOption)}
              value={selectedOption}
              data-test-id={`freq_select_day`}
            >
              {DaysOfWeeks.map((day) => {
                return (
                  <Option
                    className="selectOptions"
                    key={day.value}
                    value={day.value}
                    data-test-id={`freq_option_${day.value}`}
                    style={{ borderBottom: '1px solid #ccc', marginTop: '0px', marginLeft: '5px', marginRight: '15px' }}
                  >
                    {day.label}
                  </Option>
                );
              })}
            </Select>
          </Col>
        </div>
      )}
    </Row>
  );
};
