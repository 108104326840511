import React, { useState } from 'react';

import { WizardProps } from '../../components-v2/Wizard';
import SisSyncSettings from '.';
import { FrequencyOptions } from '../../components-v2/PSDataImportStudentRecords/dataUpdateFrequency';

const SisStaffSyncSettings = (props: WizardProps): React.ReactElement => {
  const [shouldInactivateRecords, setShouldInactivateRecords] = useState(true);
  const [overwriteNavData, setOverwriteNavData] = useState(false);
  const [includeMappedRole, setIncludeMappedRole] = useState(true);
  const defaultFrequency = 'TWENTY_FOUR_HOURS';

  const frequencies: FrequencyOptions = [
    {
      value: 'FOUR_HOURS',
      label: '4 hours',
    },
    {
      value: 'TWELVE_HOURS',
      label: '12 hours',
    },
    {
      value: 'TWENTY_FOUR_HOURS',
      label: '24 hours',
    },
  ];

  return (
    <SisSyncSettings
      frequencies={frequencies}
      defaultFrequency={defaultFrequency}
      shouldInactivateRecords={shouldInactivateRecords}
      overwriteNavData={overwriteNavData}
      doNotOverwriteEmail={true}
      includeMappedRole={includeMappedRole}
      flagNames={['shouldInactivateRecords', 'overwriteNavData','includeMappedRole']}
      setFlagCallees={[setShouldInactivateRecords, setOverwriteNavData, setIncludeMappedRole]}
      showRadio={true}
      type="staff-records"
      includeInactivateRule={true}
      includeOverwriteRule={true}
      includeOverwriteEmailRule={false}
      includeSchools={true}
      fetchAvailableSchoolMappings={true}
      {...props}
    />
  );
};

export default SisStaffSyncSettings;
