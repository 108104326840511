import * as React from 'react';
import CreditTypeDefineCode from '../../components-v2/SisDefineCodes/creditTypeDefineCode';
import CourseHistoryAcademicYear from '../../components-v2/SisDefineCodes/courseHistoryAcademicYear';
import TermDefineCodes from '../../components-v2/SisDefineCodes/term';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';
import { ValueMappingItem } from './SisCourseCatalogDefineCodes';
import { getCurrentAcademicYear } from '../../utils/utils';

export const currentCoursesParameterGroupUrl = 'data-ingest/sis/parameter-group/course-current';
export const courseCurrentTermsUrl = 'data-ingest/sis/ps-distinct-term-abbreviations';
export const termColumnHeaderName = 'Term';
export const creditTypeColumnHeaderName = 'Credit_Type';

export interface ValueMappings {
  [columnName: string]: ValueMappingItem[];
}

export interface TranslateConfigParameterGroup {
  valueMappings: ValueMappings;
  // eslint-disable-next-line @typescript-eslint/ban-types
  headerMappings: object;
  removeEmptyHeaders: boolean;
  removeUnmappedHeaders: boolean;
}

export interface ValidateValueConfig {
  validValues: string[];
  validWithWarningValues: string[];
}

export interface ValidateColumnValidation {
  [columnName: string]: ValidateValueConfig;
}

export interface ValidateConfigParameterGroup {
  columns: ValidateColumnValidation;
}

export interface CourseCurrentDefineCodesParameterGroup {
  fromAcademicYear: string | number;
  creditTypes: string[];
  storeCodes: string[];
}

const SisCurrentCoursesDefineCodes = (props: WizardProps): React.ReactElement => {
  const [parameterGroup, setParameterGroup] = React.useState({});
  const [translateConfig, setTranslateConfig] = React.useState<TranslateConfigParameterGroup>({
    headerMappings: {},
    valueMappings: {},
    removeEmptyHeaders: false,
    removeUnmappedHeaders: false,
  });
  const [defineCodesSettings, setDefineCodesSettings] = React.useState<CourseCurrentDefineCodesParameterGroup>({
    fromAcademicYear: getCurrentAcademicYear(),
    creditTypes: [],
    storeCodes: [],
  });
  const [allowedCreditTypes, setAllowedCreditTypes] = React.useState<string[]>([]);
  const [allowedTerms, setAllowedTerms] = React.useState<string[]>([]);
  const [useTerms, setUseTerms] = React.useState<boolean>(false);
  const [useCreditTypes, setUseCreditTypes] = React.useState<boolean>(false);

  const saveMapping = async (): Promise<boolean> => {
    const newDefineCodesSettings: CourseCurrentDefineCodesParameterGroup = {
      fromAcademicYear: getCurrentAcademicYear(),
      creditTypes: [],
      storeCodes: [],
    };
    if (!useTerms) delete newDefineCodesSettings.storeCodes;
    else if (allowedTerms.length > 0) {
      newDefineCodesSettings.storeCodes = allowedTerms;
    } else if (allowedTerms.length == 0) {
      showNotification(NotificationTypes.error, 'Term Cannot Be Empty', 'Term not selected!.');
      return;
    }

    if (allowedCreditTypes.length > 0) {
      newDefineCodesSettings.creditTypes = allowedCreditTypes;
    }

    await apiClient.patch(currentCoursesParameterGroupUrl, {
      ...parameterGroup,
      defineCodes: newDefineCodesSettings,
    });

    setDefineCodesSettings(newDefineCodesSettings);
    return true;
  };

  const getMappings = async () => {
    try {
      const { data } = await apiClient.get(currentCoursesParameterGroupUrl);
      setParameterGroup(data);

      const {
        translateConfig = {
          valueMappings: {},
          headerMappings: {},
          removeEmptyHeaders: false,
          removeUnmappedHeaders: false,
        },
        defineCodes = defineCodesSettings,
      }: { translateConfig: TranslateConfigParameterGroup; defineCodes: CourseCurrentDefineCodesParameterGroup } = data;

      setTranslateConfig(translateConfig);
      setAllowedTerms(defineCodes?.storeCodes || []);
      setAllowedCreditTypes(defineCodes?.creditTypes || []);

      if (translateConfig.headerMappings) {
        for (const columnValue of Object.values(translateConfig.headerMappings)) {
          if (columnValue === termColumnHeaderName) {
            setUseTerms(true);
          } else if (columnValue === creditTypeColumnHeaderName) {
            setUseCreditTypes(true);
          }
        }
      }
    } catch (err) {
      console.error('getMappings Error:', err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mappings', 'Failure in getting data from server.');
    }
  };

  const creditTypeDefineCodes = React.useMemo(() => {
    return (
      <CreditTypeDefineCode
        key="sis-credit-type"
        saveCreditTypes={(credits) => {
          setAllowedCreditTypes(credits);
        }}
        creditTypes={allowedCreditTypes}
      />
    );
  }, [allowedCreditTypes]);

  const termDefineCodes = React.useMemo(() => {
    return (
      <TermDefineCodes
        terms={allowedTerms}
        setTerms={(val) => {
          setAllowedTerms(val);
        }}
        termValue="[Terms]Abbreviation"
        key={'term-def-code'}
        termConfigEndpoint={courseCurrentTermsUrl}
      />
    );
  }, [allowedTerms]);

  const sections = [];
  if (useTerms) {
    sections.push(termDefineCodes);
  }

  if (useCreditTypes) {
    sections.push(creditTypeDefineCodes);
  }

  return <SisDefineCodes codesToDefine={sections} saveMapping={saveMapping} getMappings={getMappings} {...props} />;
};

export default SisCurrentCoursesDefineCodes;
