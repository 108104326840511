import type {
  SisCourseCatalogJobSummary,
  SisStudentJobSummary,
  SisReconciled
} from '../../../../../../libs/common-interfaces';
import { Alert, Col, Row, Spin, Table, Button } from 'antd';
import * as React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { API_URL } from '../../utils/constants';
import MainTemplate from '../Shell/MainTemplate';
import LeftAngleIcon from '../../../assets/Icon/LeftAngleIcon';
import PSDataImportJobReconcile from './PSDataImportJobReconcile';
import AlertErrorIcon from '../../../assets/Icon/AlertErrorIcon';
import { styles } from '../../../assets/Icon/CustomIcon';
import Paragraph from 'antd/lib/typography/Paragraph';
import Title from 'antd/lib/typography/Title';
import Text from 'antd/lib/typography/Text';
import courseCatalogImportData from './PSCourseCatalogImportData';
import studentImportData from './PSStudentImportData';
import courseMappingImportData from './PSCourseMappingImportData';
import courseHistoryImportData from './PSCourseHistoryImportData';
import courseCurrentImportData from './PSCourseCurrentImportData';
import courseRequestImportData from './PSCourseRequestImportData';
import { checkLegacyClever, getIngressType } from '../../utils/utils';
import ClassDetails, { classDetailsData } from './ClassDetails/ClassDetails';
import SubjectAreaDetails, { subjectAreaDetails } from './SubjectAreaDetails/SubjectAreaDetails';
import PSDataImportFailedJobReconcile from './PSDataImportFailedJobReconcile';
// component specific styling
import './styles.scss';
import { featureFlags } from '../../utils/featureFlags';
import { IntegrationContext } from '../../utils/context';
import { StatusMessages } from '../../utils/jobStatus';
import SummaryPerDetails, { importDetailsPerSChool, summaryBasedOnSchoolId } from './SummaryPerSchool/SummaryPerSchool';
import staffImportData from './PSStaffRecordsData';
import parentImportData from './PSParentsRecordsData';
import SchoolDetails, { SchoolDetailsData } from './SchoolDetails/SchoolDetails';

const PSDataImportJobSummary: React.FC = () => {
  const history = useHistory();
  const { jobGuid } = useParams<{ jobGuid: string }>();
  const [jobMeta, setJobMeta] = React.useState<SisStudentJobSummary>();
  const [summaryData, setSummaryData] = React.useState<ITableDataItem[]>();
  const [detailsData, setDetailsData] = React.useState<ITableDataItem[]>();
  const [classDetailsData, setClassDetailsData] = React.useState<classDetailsData[]>();
  const [schoolDetailsData, setSchoolDetailsData] = React.useState<SchoolDetailsData[]>();
  const [subjectAreaStatsData, setSubjectAreaStatsData] = React.useState<subjectAreaDetails[]>();
  const [undoData, setUndoData] = React.useState<ITableDataItem[]>();
  const [loading, setLoading] = React.useState<boolean>();
  const [reconciled, setReconciled] = React.useState<SisReconciled>({});
  const [dataType, setDataType] = React.useState('');
  const [reconcileDisabled, setReconcileDisabled] = React.useState<boolean>(false);
  const [startImport, setStartImport] = React.useState<boolean>(true);
  const [updatesResolvedData, setUpdatesResolvedData] = React.useState<ITableDataItem[]>();
  const context = React.useContext(IntegrationContext);
  const [summaryPerSchoolDetails, setSummaryPerSchoolDetails] = React.useState<summaryBasedOnSchoolId[]>();

  const fetchJob = async () => {
    try {
      const { data } = await apiClient.get(`/data-ingest/sis/jobs/${jobGuid}`);
      setJobMeta(data);
      compileJobData(data, history);
      setLoading(false);
      if (data?.updatesResolved?.guid && data?.updatesResolved?.guid !== '') {
        compileReconcileJobData(data);
      }
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  const postReconciled = async () => {
    try {
      setReconcileDisabled(true);
      const type = getIngressType(jobMeta.dataType);
      const { data } = await apiClient.post(`/data-ingest/sis/jobs/${jobGuid}/reconcile`, {
        reconciled,
        ingressType: type,
        isTestImport: jobMeta?.isTestImport,
        author: context.userFullName,
      });
      const { started, error } = data;
      if (started) {
        showNotification(
          NotificationTypes.success,
          'Import is in progress.',
          'Large import may take a few minutes to complete.',
        );
      }
      if (error) {
        showNotification(NotificationTypes.error, 'Error Starting Reconciliation Job', `${error}`);
      }
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  const reStartImport = async () => {
    try {
      setStartImport(false);
      const import_type = getIngressType(jobMeta.dataType);
      const { data } = await apiClient.get(`/data-ingest/ingress/${import_type}`);
      if (import_type.includes('course-')) {
        await apiClient.post('/data-ingest/sis/start', {
          type: import_type,
          isTestImport: false,
          cron: data?.cron,
          author: context.userFullName,
        });
        showNotification(
          NotificationTypes.success,
          'Import is in progress.',
          'Large import may take a few minutes to complete.',
        );
      } else {
        postReconciled();
      }

      if (featureFlags['feature.dataIngest.cplan.autoNavigateToIntegration']) {
        history.goBack();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Starting Job', 'Server Error');
    }
  };

  React.useEffect(() => {
    setLoading(true);
    void fetchJob();
  }, []);

  React.useEffect(() => {
    if (jobMeta?.dataType) {
      setDataType(getIngressType(jobMeta?.dataType));
    }
  }, [jobMeta]);

  const columns = [
    {
      title: 'Log Metric Name',
      dataIndex: 'name',
      width: 250,
    },
    {
      title: 'Log Metric Value',
      dataIndex: 'value',
    },
  ];

  const headings = {
    summary: `${jobMeta?.isTestImport ? 'Test ' : ''} 
    ${jobMeta?.dataType === 'Course Request' ? 'Sync ' : 'Import '}Summary`,
    detail: `${jobMeta?.isTestImport ? 'Test ' : ''}
    ${jobMeta?.dataType === 'Course Request' ? 'Sync ' : 'Import '}Details`,
  };

  interface ITableDataItem {
    name: string;
    key: string;
    value: any;
    render?: any;
  }

  const compileJobData = (job: SisStudentJobSummary | SisCourseCatalogJobSummary, history) => {
    const errorRowsPerCategory: { [category: string]: number } = Object.keys(
      job?.validationResults.errors || {},
    ).reduce((p, c) => {
      return { ...p, [c]: job.validationResults.errors[c].count };
    }, {});
    const errorColumnsPerCategory: { [category: string]: string[] } = Object.keys(
      job?.validationResults.errors || {},
    ).reduce((p, c) => {
      return { ...p, [c]: job.validationResults.errors[c].columns };
    }, {});
    const warningRowsPerCategory: { [category: string]: number } = Object.keys(
      job?.validationResults.warnings || {},
    ).reduce((p, c) => {
      return { ...p, [c]: job.validationResults.warnings[c].count };
    }, {});
    const warningColumnsPerCategory: { [category: string]: string[] } = Object.keys(
      job?.validationResults.warnings || {},
    ).reduce((p, c) => {
      return { ...p, [c]: job.validationResults.warnings[c].columns };
    }, {});
    const hasIssues = job?.validation?.invalidCount > 0 || job?.validation?.warningCount > 0;

    let detailsData = [],
      undoData = [],
      summaryData = [],
      classDetailsData = [],
      schoolDetailsData = [],
      subjectAreaStatsData = [],
      summaryPerSchoolDetails = [];

    if (job?.dataType === 'Course Catalog') {
      summaryData = courseCatalogImportData.courseCatalogSummaryData(job);
      detailsData = courseCatalogImportData.courseCatalogDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
        jobGuid,
      );
      subjectAreaStatsData = courseCatalogImportData.subjectAreaDetailsData(job);
    } else if (job?.dataType === 'Course Mapping') {
      summaryData = courseMappingImportData.courseMappingSummaryData(job);
      detailsData = courseMappingImportData.courseMappingDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
      );
    } else if (job?.dataType === 'Course Request') {
      summaryData = courseRequestImportData.courseRequestSummaryData(job);
      detailsData = courseRequestImportData.courseRequestDetailsData(job);
    } else if (job?.dataType === 'Course History' || job?.dataType === 'Student Course Data (Course History)') {
      summaryData = courseHistoryImportData.courseHistorySummaryData(job);
      detailsData = courseHistoryImportData.courseHistoryDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
        jobGuid,
      );
      summaryPerSchoolDetails = importDetailsPerSChool.summaryPerSchoolData(job);
    } else if (job?.dataType === 'Course Current' || job?.dataType === 'Student Course Data (Current Courses)') {
      summaryData = courseCurrentImportData.courseCurrentSummaryData(job);
      detailsData = courseCurrentImportData.courseCurrentDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
        jobGuid,
      );
      summaryPerSchoolDetails = importDetailsPerSChool.summaryPerSchoolData(job);
    } else if (job?.dataType === 'Staff Records') {
      undoData = staffImportData.undoData(job);
      summaryData = staffImportData.staffSummaryData(job);
      detailsData = staffImportData.staffDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
        history,
      );
      schoolDetailsData = staffImportData.schoolDetailsData(job, context);
    }else if (job?.dataType === 'Parent Records') {
      undoData = parentImportData.undoData(job);
      summaryData = parentImportData.parentSummaryData(job);
      detailsData = parentImportData.parentDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
      );
      schoolDetailsData = parentImportData.schoolDetailsData(job, context);
    } 
    else {
      summaryData = studentImportData.studentSummaryData(job);

      detailsData = studentImportData.studentDetailsData(
        hasIssues,
        job,
        errorColumnsPerCategory,
        errorRowsPerCategory,
        warningColumnsPerCategory,
        warningRowsPerCategory,
        history,
      );
      classDetailsData = studentImportData.classDetailsData(job);
      undoData = studentImportData.undoData(job);
    }

    setSummaryData(summaryData);
    setDetailsData(detailsData);
    setClassDetailsData(classDetailsData);
    setSchoolDetailsData(schoolDetailsData);
    setUndoData(undoData);
    setSubjectAreaStatsData(subjectAreaStatsData);
    setSummaryPerSchoolDetails(summaryPerSchoolDetails);
  };

  const compileReconcileJobData = (job: SisStudentJobSummary | SisCourseCatalogJobSummary) => {
    let updateResolveData = [];
    if (job?.dataType === 'Course Catalog')
      updateResolveData = courseCatalogImportData.courseCatalogUpdatedResolvedData(job, history, context);
    else if (job?.dataType === 'Course Mapping')
      updateResolveData = courseMappingImportData.courseMappingUpdatedResolvedData(job, history, context);
    else updateResolveData = studentImportData.studentUpdatedResolvedData(job, history, context);
    setUpdatesResolvedData(updateResolveData);
  };

  function getErrorMessage() {
    if (jobMeta?.psDownError) {
      if (jobMeta?.psDownError === 'PowerSchool Down' || jobMeta?.psDownError === 'No Response from PS SIS') {
        return `Currently ${
          jobMeta?.sis_source === 'clever' ? 'Clever' : jobMeta?.sis_source
        } Server is down. Users are not allowed to perform Data Import until ${
          jobMeta?.sis_source
        } is back online. Please try import after some time.`;
      } else if (
        jobMeta?.psDownError === 'Data Issue' &&
        jobMeta?.referenceId !== null &&
        jobMeta?.sis_source === 'eSchoolPlus SIS'
      ) {
        return `${jobMeta?.sis_source} has some corrupt data. Please check SIS system for error using reference ID - ${jobMeta?.referenceId}. User are not allowed to perform data import until ${jobMeta?.sis_source} has proper data. Please try import after fixing the data.`;
      } else {
        return `There is a problem connecting to ${
          jobMeta?.sis_source === 'clever' ? 'Clever' : jobMeta?.sis_source + ' SIS'
        } system using configured connection settings. Please update the connection settings to resume imports`; // Bad Creds
      }
    } else if (jobMeta?.maxReconcileError) {
      return 'There are too many manual matches fields for SIS Data Import. Please check if all the fields are mapped correct or there is some issue with the records in the SIS system. To proceed with the Data import this need to be fixed.';
    } else {
      return 'There was an unexpected system error while processing this import.';
    }
  }

  return (
    <MainTemplate
      title="View Log"
      titleTestId="data_job_summary_title"
      classes="new-data-import mainLogsSection mainConfigSection"
    >
      <>
        {loading && (
          <Row justify="center">
            <Col span={12}>
              <div className="spinner">
                <Spin size="large" />
              </div>
            </Col>
          </Row>
        )}
        {jobMeta && (
          <>
            {jobMeta?.status !== 'FAILED' && jobMeta?.isTestImport && jobMeta?.mostRecent && (
              <Row justify="space-between" align="middle" style={{ marginBottom: '10px' }}>
                <Col span={14}>
                  <Alert
                    className="alert"
                    style={{ border: '0px', borderLeft: '2px solid #6BD7DB', backgroundColor: '#DEF6F7' }}
                    message={<Title level={5}>This is a test import</Title>}
                    description={
                      <Text>
                        Nothing has been processed until you click <b>Start import</b>
                      </Text>
                    }
                    type="success"
                  />
                </Col>
              </Row>
            )}
            <Row justify="space-between" align="middle">
              <Col span={4}>
                <div style={{ float: 'left', width: '30px' }}>
                  <LeftAngleIcon />
                </div>
                <div style={{ float: 'left' }}>
                  <a className="backbtn" onClick={() => history.goBack()}>
                    Back
                  </a>
                </div>
              </Col>
              <Col span={10}>
                <div style={{ float: 'right' }}>
                  <button
                    className="button"
                    onClick={() => (window.location.href = `${API_URL}/data-ingest/sis/jobs/${jobGuid}/download/logs`)}
                    data-test-id="export_btn"
                  >
                    Export Log
                  </button>
                  {jobMeta?.status !== 'FAILED' && jobMeta?.isTestImport && jobMeta?.mostRecent && (
                    <Button
                      style={{ marginLeft: '10px' }}
                      className="button button-blue"
                      disabled={reconcileDisabled || !startImport || context.isLegacyCleverEnabled}
                      title={context.isLegacyCleverEnabled ? StatusMessages.LEGACY_CLEVER_ENABLED : ''}
                      onClick={() => reStartImport()}
                    >
                      Start Import
                    </Button>
                  )}
                </div>
              </Col>
              <Col span={10}></Col>
            </Row>
            {jobMeta?.status === 'FAILED' ? (
              <div>
                <br />
                <Row>
                  <Col span={14}>
                    <Alert
                      className="alert"
                      message={<Title level={5}>Critical Error - Import Cancelled</Title>}
                      description={
                        jobMeta?.requiredFieldsNotMapped ? (
                          <>
                            <Text>
                              Below are required fields that must be shared with Naviance from Clever in order to
                              proceed with the student import.
                            </Text>
                            <ul>
                              {jobMeta?.requiredFieldsNotMapped.map((field) => (
                                <li key={field}>{field}</li>
                              ))}
                            </ul>
                          </>
                        ) : (
                          <Text>{getErrorMessage()}</Text>
                        )
                      }
                      type="error"
                      icon={<AlertErrorIcon customStyles={{ ...styles, width: '40px' }} />}
                      showIcon
                    />
                  </Col>
                </Row>
                <br />
                {!jobMeta?.psDownError && !jobMeta?.maxReconcileError && !jobMeta?.requiredFieldsNotMapped && (
                  <Row>
                    <Col span={1}></Col>
                    <Col span={13}>
                      <Paragraph>Contact the customer support team if the problem persist.</Paragraph>
                    </Col>
                  </Row>
                )}
              </div>
            ) : (
              <></>
            )}
            {jobMeta?.status !== 'FAILED' &&
            jobMeta?.mostRecent &&
            jobMeta?.toReconcile &&
            !jobMeta?.reconcileJob &&
            !jobMeta?.isReconcilable ? (
              <div>
                <br />
                <Row>
                  <Col span={14}>
                    <Alert
                      className="alert"
                      style={{ border: '0px', borderLeft: '2px solid #6BD7DB', backgroundColor: '#DEF6F7' }}
                      message={
                        <Text>
                          Reconcile records will only display for 48 hours. Please re-run import to retrieve record
                          data.
                        </Text>
                      }
                      type="success"
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <></>
            )}
            {jobMeta?.status === 'FAILED' && jobMeta?.statusMessage?.includes('multiple matches') ? (
              <Row>
                <Col span={1}></Col>
                <Col span={13}>
                  <Paragraph>
                    We use multiple methods for ensuring an exact student record match between Naviance and your SIS:
                    <ol style={{ listStyleType: 'lower-alpha' }}>
                      <li>Match on Student ID</li>
                      <li>Match on combination of First Name, Last Name and Date of Birth</li>
                      <li>Match on Date of Birth and either First Name or split Last Name</li>
                    </ol>
                    <a
                      href={`${API_URL}/data-ingest/sis/jobs/${jobGuid}/download/logs`}
                      data-test-id={`match-export-link`}
                    >
                      Export Log for additional details
                    </a>
                  </Paragraph>
                </Col>
                <Col span={14}>
                  {jobMeta?.mostRecent && jobMeta?.toReconcile && !jobMeta?.reconcileJob && jobMeta?.isReconcilable ? (
                    <PSDataImportFailedJobReconcile
                      jobGuid={jobGuid}
                      update={(updated) => setReconciled(updated)}
                      post={() => postReconciled()}
                      reconcileDisabled={reconcileDisabled}
                      setReconcileDisabled={(updated) => setReconcileDisabled(updated)}
                      dataType={dataType}
                    />
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <h1>{headings.summary}</h1>
            <Row>
              <Col span={14}>
                <Table
                  columns={columns}
                  dataSource={summaryData}
                  showHeader={false}
                  className="viewLogTable"
                  pagination={false}
                />
              </Col>
            </Row>

            {jobMeta?.updatesResolved && jobMeta?.updatesResolved?.guid !== '' && !jobMeta?.dataType.includes('Staff Records') && (
              <>
                <h1 className="paddedH1">Potential Updates Resolved</h1>
                <Row>
                  <Col span={14}>
                    <Table
                      columns={columns}
                      dataSource={updatesResolvedData}
                      showHeader={false}
                      className="viewLogTable"
                      pagination={false}
                    />
                  </Col>
                </Row>
              </>
            )}
            {!jobMeta?.undo?.status && (
              <>
                <h1 className="paddedH1">{headings.detail}</h1>
                <Row>
                  <Col span={14}>
                    <Table
                      columns={columns}
                      dataSource={detailsData}
                      showHeader={false}
                      className="viewLogTable"
                      pagination={false}
                    />
                  </Col>
                </Row>
                {featureFlags['feature.dataIngest.psi397'] && jobMeta?.dataType.includes('Student Records') ? (
                  <Row>
                    <Col span={14}>
                      <ClassDetails dataSource={classDetailsData}></ClassDetails>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                {jobMeta?.dataType.includes('Staff Records') ? (
                  <Row>
                    <Col span={18}>
                      <SchoolDetails dataSource={schoolDetailsData}></SchoolDetails>
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
              </>
            )}
            {jobMeta?.undo?.status ? (
              <>
                <h1 className="paddedH1">Undo Details</h1>
                <Row>
                  <Col span={14}>
                    <Table
                      columns={columns}
                      dataSource={undoData}
                      showHeader={false}
                      className="viewLogTable"
                      pagination={false}
                    />
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {featureFlags['feature.dataIngest.cplan.subjectAreaStats'] &&
            jobMeta?.dataType.includes('Course Catalog') ? (
              <Row>
                <Col>
                  <SubjectAreaDetails dataSource={subjectAreaStatsData}></SubjectAreaDetails>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            {featureFlags['feature.dataIngest.cplan.summaryPerSchoool'] &&
            (jobMeta?.dataType.includes('Current Courses') || jobMeta?.dataType.includes('Course History')) ? (
              <Row>
                <Col>
                  <SummaryPerDetails dataSource={summaryPerSchoolDetails} />
                </Col>
              </Row>
            ) : (
              <></>
            )}
          </>
        )}
        {jobMeta?.status !== 'FAILED' &&
        jobMeta?.mostRecent &&
        jobMeta?.toReconcile &&
        // !jobMeta?.reconcileJob &&
        jobMeta?.isReconcilable ? (
          <PSDataImportJobReconcile
            jobGuid={jobGuid}
            update={(updated) => setReconciled(updated)}
            post={() => postReconciled()}
            reconcileDisabled={reconcileDisabled || jobMeta?.isTestImport}
            setReconcileDisabled={(updated) => setReconcileDisabled(updated)}
            dataType={dataType}
          />
        ) : (
          <></>
        )}
      </>
    </MainTemplate>
  );
};

export default PSDataImportJobSummary;
