import * as React from 'react';
import { CheckCircleOutlined, CloseCircleOutlined, CloseOutlined } from '@ant-design/icons';
import Col from '../../components-v2/Col';
import Drawer from '../../components/Drawer';
import Row from '../../components-v2/Row';
import { Select, Tag } from 'antd';

// local styling
import './styles.scss';

export interface ImportNotificationsDrawerProps {
  visible: string | null;
  type: string;
  validateEmail: (email: string) => boolean;
  setDrawer: (type: any) => void;
  splitEmails: string[];
  addSave: { [key: string]: any };
  updateNotificationSettings: () => void;
  setSplitEmails: (emails: string[]) => void;
}

const successColor = '#A0F8DE';
const successText = '#232328';
const errorColor = '#DA1E38';
const errorText = '#FFF';

const ImportNotificationsDrawer = (props: ImportNotificationsDrawerProps): React.ReactElement => {
  const { type, splitEmails, setSplitEmails, addSave, updateNotificationSettings, visible, setDrawer, validateEmail } =
    props;
  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    const validEmail = validateEmail(value);
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: validEmail ? successText : errorText, fill: validEmail ? successText : errorText }}
        closeIcon={
          <CloseOutlined
            style={{ color: validEmail ? successText : errorText, fill: validEmail ? successText : errorText }}
          />
        }
        color={validEmail ? successColor : errorColor}
        icon={validEmail ? <CheckCircleOutlined /> : <CloseCircleOutlined />}
      >
        {value}
      </Tag>
    );
  };

  const closeDrawer = () => {
    setDrawer(null);
  };

  const dataType = (type: string) => {
    const types = {
      studentRecords: 'Student Records',
      alumni: 'Student Records (Alumni)',
      courseCatalog: 'Course Catalog',
      courseMapping: 'Course Mapping',
      courseHistory: 'Student Course Data (Course History)',
      currentCourses: 'Student Course Data (Current Courses)',
      courseRequest: 'Course Request',
      default: 'Unknown',
    };

    return types[type] || types.default;
  };

  if (!visible) {
    return null;
  }
  return (
    <Drawer
      visible={true}
      onClose={closeDrawer}
      content={
        <div>
          <Row style={{ marginBottom: '20px' }}>
            <Col span={20}>
              <h2 style={{ marginBottom: 0 }}> {dataType(type)} Email Notifications</h2>
              <p>Add multiple emails separated by commas.</p>
            </Col>
          </Row>
          <Row>
            <Col span={20}>
              <p>Add Email Recipient(s)</p>
              <Row>
                <Col span={20}>
                  <Select
                    mode="tags"
                    size="large"
                    tagRender={tagRender}
                    defaultValue={splitEmails}
                    style={{ width: '100%' }}
                    options={[]}
                    tokenSeparators={[',']}
                    dropdownStyle={{ display: 'none' }}
                    // placeholder="Add email addresses separated by a comma"
                    onChange={(change) => {
                      setSplitEmails(change);
                    }}
                    className="emailRecipientSelector"
                    data-test-id="email_type"
                  />
                </Col>
                <Col span={4}>
                  <button
                    className="button"
                    style={{ marginLeft: '20px', marginTop: '5px' }}
                    data-test-id="add_emails_btn"
                    disabled={addSave.disabled}
                    onClick={(e) => {
                      e.preventDefault();
                      updateNotificationSettings();
                    }}
                  >
                    {addSave.label}
                  </button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      }
    />
  );
};

export default ImportNotificationsDrawer;
