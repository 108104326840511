import * as React from 'react';
import SisAlumniStartImport from '../SisStartImport/SisAlumniStartImport';
import SisAlumniDefineCodes from '../SisDefineCodes/SisAlumniDefineCodes';
import SisAlumniSyncSettings from '../SisSyncSettings/SisAlumniSyncSettings';
import SisImport from '.';
import SisAlumniFieldMatching from '../SisFieldMatching/SisAlumniFieldMatching';

export const AlumniImportSteps = [
  { title: 'Match Fields', content: SisAlumniFieldMatching, path: '/import-wizard/alumni/match-fields' },
  { title: 'Define Codes', content: SisAlumniDefineCodes, path: '/import-wizard/alumni/define-code' },
  { title: 'Sync Settings', content: SisAlumniSyncSettings, path: '/import-wizard/alumni/sync-settings' },
  { title: 'Test / Start Import', content: SisAlumniStartImport, path: '/import-wizard/alumni/test-start-import' },
];

const SisAlumniImport = (): React.ReactElement => {
  return <SisImport steps={AlumniImportSteps} />;
};

export default SisAlumniImport;
