import * as React from 'react';
import { Row, Col, Table, Select, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { getSisExtensionsData } from '../../utils/getSisExtensionsData'

interface iPSSisHomeRooms {
  home_room: string;
}

interface HomeRoomMappingProps {
  homeRoomMapping: Record<string, string[]>;
  extentionConfig: any;
  setHomeRoomMapping: (homeRoomMapping: Record<string, string[]>) => void;
}

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

const HomeRoomMapping = ({
  homeRoomMapping,
  extentionConfig,
  setHomeRoomMapping,
}: HomeRoomMappingProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);
  const [navianceHomeRooms, setNavianceHomeRooms] = React.useState([]);
  const [psSisHomeRooms, setPsSisHomeRooms] = React.useState([]);

  const getMappedSchools = async (): Promise<string> => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/schoolMapping');
      if (data.schoolMapping) {
        const mappedSchools = data.schoolMapping.map((school) => {
          return school.nid;
        });
        return mappedSchools.join(',');
      }
      return '';
    } catch (error) {
      console.error(error.message);
      showNotification(NotificationTypes.error, 'Error Getting Mapped Schools', 'Failure in getting data from server.');
    }
  };

  const getNavianceHomeRoom = async () => {
    try {
      const mappedSchools = await getMappedSchools();
      const { data } = await apiClient.get(`/highschools/home-rooms?schoolIds=${mappedSchools}`);
      setNavianceHomeRooms(data);
    } catch (error) {
      console.error(error.message);
      showNotification(
        NotificationTypes.error,
        'Error Getting Naviance Home Rooms',
        'Failure in getting data from server.',
      );
    }
  };

  const getPSSisHomeRoom = async () => {
    try {
      if (extentionConfig) {
        const receivedPSSishomeRooms = await getSisExtensionsData(extentionConfig);
        setPsSisHomeRooms(receivedPSSishomeRooms);
      } else {
        const { data } = await apiClient.get('/data-ingest/sis/sis-home-rooms');
        const receivedPSSishomeRooms = data
          .filter((record: iPSSisHomeRooms) => record.home_room)
          .map((record: iPSSisHomeRooms) => {
            return { label: record.home_room, value: record.home_room };
          });
        setPsSisHomeRooms(receivedPSSishomeRooms);
      }
    } catch (error) {
      console.error(error.message);
      showNotification(
        NotificationTypes.error,
        'Error Getting PS SIS Home Rooms',
        'Failure in getting data from server.',
      );
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    getNavianceHomeRoom();
    getPSSisHomeRoom();
    if (isMountedRef.current) setLoading(false);
  };

  const changeHomeRoomMapping = (key: string, value: string) => {
    const updatedMapping = { ...homeRoomMapping };
    updatedMapping[key] = [value];
    setHomeRoomMapping(updatedMapping);
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const homeRoomColumns = [
    {
      title: 'Naviance Field: Home Room',
      width: '40%',
      render: (data) => <>{data.name}</>,
    },
    {
      title: 'SIS Code: Home_Room',
      width: '60%',
      render: (data) => (
        <Select
          size="large"
          tagRender={tagRender}
          defaultValue={homeRoomMapping[data.id] ? homeRoomMapping[data.id][0] : ''}
          style={{ width: '100%' }}
          tokenSeparators={[',']}
          placeholder="Select HomeRoom"
          onChange={(val) => {
            changeHomeRoomMapping(data.id, val);
          }}
        >
          {psSisHomeRooms.map((homeRoom, ind) => (
            <Option
              className="selectOptions"
              key={`sis-${homeRoom.value}-${ind}`}
              value={homeRoom.value}
              data-test-id={`homeroom_option_${`${homeRoom.value}`}`}
            >
              {homeRoom.label}
            </Option>
          ))}
        </Select>
      ),
    },
  ];

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 className="infoHeading uploadHeading">Home Room</h2>
      </Col>
      <Col span={16}>
        <Table
          rowKey="id"
          columns={homeRoomColumns}
          dataSource={navianceHomeRooms}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default HomeRoomMapping;
