import React, { FC, useEffect, useState } from 'react';
import { get } from 'lodash';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { ImportTypeParam, CoursePlannerImportData, ImportTypeFileNames } from '../../utils/constants';
import { readFileHeaders } from '../../utils/utils';
import { API_URL } from '../../utils/constants';
import MatchField from './MatchField';
import UploadPage from './UploadPage';

type Props = {
  importType: ImportTypeParam;
};

type Pages = 'UploadPage' | 'MatchFields';

const COURSE_PLANNER_URL = '/data-import';

const CoursePlannerImport: FC<Props> = ({ importType }) => {
  const initialState = {
    importType,
    file: null,
    saveSettings: true,
    importSettings: { overrideData: false },
    fileHeaders: [],
    mapping: '{}',
    loading: false,
  };

  useEffect(() => {
    let prefix = API_URL ?? '';
    if (!prefix.endsWith('/')) {
      prefix += '/';
    }

    apiClient.get(`/user?product=7`).then((resp) => setUserData(resp.data));
  }, []);

  const [importData, setImportData] = useState<CoursePlannerImportData>(initialState);
  const [currentPage, setCurrentPage] = useState<Pages>('UploadPage');
  const [userData, setUserData] = useState<any>();

  const handleChange = async (key, value) => {
    if (key === 'file') {
      const fileHeaders = await readFileHeaders(value.file);
      setImportData({ ...importData, [key]: value.file, fileHeaders });
    } else {
      setImportData({ ...importData, [key]: value });
    }
  };

  const handleCancel = () => {
    window.location.href = `${window.location.origin}${!isSchool() ? '/district' : ''}/setupmain/dataimport/import.php`;
  };

  const handleUploadContinue = async () => {
    if (importData.file === null) {
      showNotification(NotificationTypes.error, 'Upload File', 'Please upload file to continue import.');
      return;
    }
    setCurrentPage('MatchFields');
  };

  const isSchool = (): boolean => {
    let school = false;
    try {
      if (userData.products['7'] !== 'DISTRICT') {
        school = true;
      }
    } catch (err) {
      // pass
    }
    return school;
  };

  const runJob = async (mapping) => {
    setImportData({ ...importData, loading: true });
    const formData = new FormData();
    formData.append('file', importData.file);
    formData.append('importType', importData.importType);
    formData.append('saveSettings', JSON.stringify(importData.saveSettings));
    formData.append('mapping', JSON.stringify(mapping));
    formData.append('importSettings', JSON.stringify(importData.importSettings));
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    try {
      await apiClient.post(COURSE_PLANNER_URL, formData, config);
      showNotification(
        NotificationTypes.success,
        'File Uploaded Successfully',
        'File imported successfully for data import.',
      );
      window.location.href = `${window.location.origin}/${
        !isSchool() ? 'district/' : ''
      }setupmain/dataimport/show_history.php`;
    } catch (error) {
      let errorMessage = 'Failure in sending data the server.';
      if (get(error, 'response.status') === 401) {
        errorMessage = 'You are not authorized to make this change or perform this action on behalf of the client.';
      }
      showNotification(NotificationTypes.error, 'Error Importing File', errorMessage);
      setImportData({ ...importData, loading: false });
    }
  };

  return (
    <div>
      {currentPage === 'UploadPage' && (
        <UploadPage
          importData={importData}
          handleContinue={handleUploadContinue}
          handleChange={handleChange}
          handleCancel={handleCancel}
        />
      )}
      {currentPage === 'MatchFields' && (
        <MatchField importData={importData} runJob={runJob} handleCancel={handleCancel} handleChange={handleChange} />
      )}
    </div>
  );
};

export default CoursePlannerImport;
