import * as React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { Radio, Select, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import type { NavSchoolsType } from '../../../../../../libs/common-interfaces';

import './styles.scss';

const SelectSchools = ({
  inputRadio,
  inputSchools,
  updateSchools,
  showRadio = true,
  availableMappedSchools = [],
}): React.ReactElement => {
  const [schoolRadio, setSchoolRadio] = React.useState(inputRadio);
  const [schoolMapping, setSchoolMapping] = React.useState({});
  const [navSchools, setNavSchools] = React.useState<NavSchoolsType[]>([]);
  const [availableSchools, setAvailableSchools] = React.useState([]);
  const [selectedSchools, setSelectedSchools] = React.useState<string[]>(inputSchools);

  const { Option } = Select;

  const fetchNavianceSchools = async () => {
    try {
      const res = await apiClient.get('/highschools/v2?byType=district&includeInactive=true');
      const data: NavSchoolsType[] = res.data;
      setNavSchools(data.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0)));
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting Naviance Schools',
        'Failure in getting data from server.',
      );
    }
  };

  const fetchSchoolMapping = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/schoolMapping');
      if (data.schoolMapping) {
        const mapping = data.schoolMapping.reduce((acc, value) => {
          const { sisId, nid, disabled } = value;
          acc[nid] = { nid, sisId, enabled: !disabled };
          return acc;
        }, {});

        setSchoolMapping(mapping);
      } else {
        setSchoolMapping({});
      }
    } catch (error) {
      showNotification(
        NotificationTypes.error,
        'Error Getting School Mappings',
        'Failure in getting data from server.',
      );
    }
  };

  const onChange = (e) => {
    setSchoolRadio(e.target.value);
  };

  React.useEffect(() => {
    if (schoolRadio && navSchools.length === 0) {
      fetchSchoolMapping();
    } else {
      setSelectedSchools([]);
    }
  }, [schoolRadio]);

  React.useEffect(() => {
    updateSchools(selectedSchools);
  }, [selectedSchools]);

  React.useEffect(() => {
    if (Object.keys(schoolMapping).length) {
      fetchNavianceSchools();
    }
  }, [schoolMapping]);

  React.useEffect(() => {
    const mappedSchools = Object.values(schoolMapping)
      .filter((mappedSchool: { sisId: string }) => !!mappedSchool.sisId)
      .map((mappedSchool: { nid: string }) => mappedSchool.nid);
    setAvailableSchools(
      navSchools
        .filter((navSchool) => mappedSchools.includes(navSchool.nid))
        .sort((a, b) => (a.isAccount > b.isAccount ? 1 : a.isAccount < b.isAccount ? -1 : 0)),
    );
  }, [navSchools]);

  React.useEffect(() => {
    setSelectedSchools(inputSchools);
  }, [inputSchools]);

  React.useEffect(() => {
    setSchoolRadio(inputRadio);
  }, [inputRadio]);

  function handleChange(value) {
    setSelectedSchools(value);
  }

  const tagColor = '#f5f5f5';
  const tagTextColor = '#141497';

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  return (
    <div>
      <h1>Available at Schools</h1>
      {!showRadio ? (
        <span>All Mapped Naviance Schools ({availableMappedSchools?.length || 0})</span>
      ) : (
        <Radio.Group onChange={onChange} value={schoolRadio}>
          <Radio value={0}>All Mapped Schools ({availableMappedSchools?.length || 0})</Radio>
          <Radio value={1}>Specify Schools</Radio>
        </Radio.Group>
      )}
      {schoolRadio === 0 ? (
        <></>
      ) : (
        <Select
          mode="tags"
          tagRender={tagRender}
          allowClear
          style={{ width: '100%' }}
          placeholder="Please select"
          dropdownClassName="specific-school"
          defaultValue={selectedSchools}
          onChange={handleChange}
        >
          {availableSchools.map((navSchool, i) => (
            <Option key={i.toString(36) + i} value={navSchool.nid}>
              {navSchool.isAccount === 2 ? '(Inactive) ' : ''}
              {navSchool.name}
            </Option>
          ))}
        </Select>
      )}
    </div>
  );
};

export default SelectSchools;
