import { Col, Row } from 'antd';
import * as React from 'react';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { featureFlags } from '../../utils/featureFlags';
import { getJobStatusMsg } from '../../utils/jobStatus';
import { API_URL } from '../../utils/constants';

const studentImportData = {
  studentDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
    history,
  ) => {
    let details = [];

    if (!featureFlags['feature.dataIngest.psi397']) {
      details = [
        {
          name: 'Students Added',
          key: 'Students Added',
          value: job?.importResults?.createdStudents || 0,
        },
        {
          name: 'Students Updated',
          key: 'Students Updated',
          value: job?.importResults?.updatedStudents || 0,
        },
        {
          name: 'Student Transfers',
          key: 'Student Transfers',
          value: job?.importResults?.transferredStudents || 0,
        },
        {
          name: 'Student Class Year Updates',
          key: 'Student Class Year Updates',
          value: job?.importResults?.classYearUpdates || 0,
        },
        {
          name: 'Students Deactivated',
          key: 'Students Deactivated',
          value: job?.importResults?.inactivated || 0,
        },
        {
          name: 'Records with No Changes',
          key: 'Records with No Changes',
          value: job?.importResults?.noChanges || 0,
        },
      ];
    }

    const unMappedCodes = {
      name: 'Unmapped Codes',
      key: 'Unmapped Codes',
      value: (
        <>
          <Row>
            <Col span={2}>
              <AlertYellowIcon />
            </Col>
            <Col span={22}>
              <span>
                {(job?.unMappedCodes?.counselors.length || 0) + (job?.unMappedCodes?.diplomaTypes.length || 0)}
              </span>
            </Col>
          </Row>
          {job?.unMappedCodes?.diplomaTypes?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>
                  {job?.unMappedCodes?.diplomaTypes.length || 0} Unmapped Codes:
                </span>{' '}
                DiplomaType
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.diplomaTypes.map((diplomaType) => {
                    return (
                      <li key={diplomaType}>
                        <span>{diplomaType}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          {job?.unMappedCodes?.counselors?.length > 0 ? (
            <Row>
              <Col span={2}></Col>
              <Col span={22}>
                <span style={{ fontWeight: 'bold' }}>{job?.unMappedCodes?.counselors.length || 0} Unmapped Codes:</span>{' '}
                Counselors
                <ul className="custom-bullet">
                  {job?.unMappedCodes?.counselors.map((counselor) => {
                    return (
                      <li key={counselor}>
                        <span>{counselor}</span>
                      </li>
                    );
                  })}
                </ul>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </>
      ),
    };

    const fieldsSharedChanges = {
      name: 'Field Shared Changes',
      key: 'Field Shared Changes',
      value: (
        <>
          {job?.fieldsSharedChanges?.addedFields && (
            <>
              <Row>
                <Col><b>Fields added from previous mapping</b></Col>
              </Row>
              {job?.fieldsSharedChanges?.addedFields.map((field) => {
                return (
                  <Row key={field}>
                    <Col span={1}></Col>
                    <Col>
                      <span>{field}</span>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
          {job?.fieldsSharedChanges?.removedFields && (
            <>
              <Row>
                <Col><b>Fields removed from previous mapping</b></Col>
              </Row>
              {job?.fieldsSharedChanges?.removedFields.map((field) => {
                return (
                  <Row key={field}>
                    <Col span={1}></Col>
                    <Col>
                      <span>{field}</span>
                    </Col>
                  </Row>
                );
              })}
            </>
          )}
        </>
      ),
    };

    const alerts = {
      name: 'Alerts',
      key: 'Alerts',
      value: (
        <Row>
          {hasIssues && (
            <Col span={2}>
              <AlertYellowIcon />
            </Col>
          )}
          <Col span={22}>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>
                  {(job?.recordsSkipped || 0) + (job?.validation?.warningCount || 0)}
                </span>
                {/* {job?.recordsSkipped > 0 ? ' Errors (Records Skipped)' : ''} */}
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>{job?.toReconcile ?? 0} To Reconcile</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>{job?.notTransfered ?? 0} Student Not Transferred</span>
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records Skipped
                {Number(job?.validation?.invalidCount) > 0 && (
                  <ul className="custom-bullet">
                    {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${errorRowsPerCategory[category]} ${category}`}:{' '}
                          </span>
                          {errorColumnsPerCategory[category].join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.warningCount} Warnings:`}</span> Records Not
                Skipped
                {Number(job?.validation?.warningCount) > 0 && (
                  <ul className="custom-bullet">
                    {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${warningRowsPerCategory[category]} ${category}`}:{' '}
                          </span>
                          {warningColumnsPerCategory[category].join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <a href={`${API_URL}/data-ingest/sis/jobs/${job?.id}/download/logs`} data-test-id={`match-export-link`}>
                Export Log for additional details
              </a>
            </Row>
          </Col>
        </Row>
      ),
    };

    const stuedentUpdateLog = {
      name: 'Last Potential Update Resolved',
      key: 'PotentialUpdatesResolved',
      value: (
        <Row>
          <Col span={22}>
            <Row>
              <Col>
                <a className="backbtn" onClick={() => history.push('/studentUpdates')}>
                  View Student Updates Log
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      ),
    };

    if (job?.unMappedCodes) {
      details.push(unMappedCodes);
    }
    if (job?.fieldsSharedChanges) {
      details.push(fieldsSharedChanges);
    }
    details.push(alerts);
    details.push(stuedentUpdateLog);
    return details;
  },

  undoData: (job) => {
    return [
      {
        name: 'Students Undone',
        key: 'Students Undone',
        value: job?.undo.undone,
      },
      {
        name: 'Students Skipped',
        key: 'Students Skipped',
        value: job?.undo.skipped,
      },
      {
        name: 'Students Reactivated',
        key: 'Students Reactivated',
        value: job?.undo.reactivated,
      },
    ];
  },

  studentSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source === 'clever' ? 'Clever' : job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount + job?.notTransfered : 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },

  classDetailsData: (job:any) : any => {
    const readLegacyData = job.importResults.importResultsClassWise ? job.importResults.importResultsClassWise : [];

    if (readLegacyData?.length === 0) {
      return [];
    }

    const years = [];
    
    readLegacyData.forEach((element) => {
      years.push(element.year);
    });

    const uniqueYears = [...new Set(years)];

    const importDetails = [];

    uniqueYears.forEach((element) => {
      const index = readLegacyData.findIndex((el) => el.year == element);
      const temp : any = {};
      temp.totalRecords = readLegacyData[index].totalRecords ?? 0;
      temp.year = element ?? 0;
      temp.invalid = readLegacyData[index].invalid ?? 0;
      temp.updated = readLegacyData[index].updated ?? 0;
      temp.created = readLegacyData[index].created ?? 0;
      temp.noChanges = readLegacyData[index].noChanges ?? 0;
      temp.transfered = readLegacyData[index].transfered ?? 0;
      temp.reconcile = readLegacyData[index].reconcile ?? 0;
      temp.missing = readLegacyData[index].missing ?? 0;
      temp.deactivateInNaviance = readLegacyData[index].deactivateInNaviance ?? 0;
      temp.reactivated = readLegacyData[index].reactivated ?? 0;
      temp.notTransfered = readLegacyData[index].notTransfered ?? 0;
      importDetails.push(temp);
    });

    const importDetailsSorted = importDetails.sort(function (a: any, b: any) {
      return a.year - b.year;
    });

    const finalImportDetails = [];

    importDetailsSorted.forEach((element) => {
      const temp = {
        ...element,
      };
      temp.activeRecords = element.created + element.updated + element.noChanges + element.reconcile;;
      temp.updateWithTransfer = `${element.updated} (${element.transfered})`;
      finalImportDetails.push(temp);
    });

    return finalImportDetails;
  },

  studentUpdatedResolvedData: (job, history, context) => {
    return [
      {
        name: 'Last Completed by',
        key: 'Last Completed by',
        value: job?.updatesResolved?.author,
      },
      {
        name: 'Last Completed on',
        key: 'Last Completed on',
        value: job?.updatesResolved?.end ? new Date(job?.updatesResolved?.end).toLocaleString() : '',
      },
      {
        name: 'Potential Updates Resolved',
        key: 'Potential Updates Resolved',
        value: (
          <Row>
            <Col span={22}>
              <Row>
                <Col>{job?.updatesResolved.recordsMatched + job?.updatesResolved.newRecordsAdded || 0}</Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.recordsMatched || 0
                  } records matched`}</span>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <span style={{ fontWeight: 'bold' }}>{`${
                    job?.updatesResolved.newRecordsAdded || 0
                  } records added`}</span>
                </Col>
              </Row>
            </Col>
          </Row>
        ),
      },
      {
        name: 'Student Updates Log',
        key: 'Student Updates Log',
        value: (
          <a className="backbtn" onClick={() => history.push('/studentUpdates')}>
            View Student Updates Log
          </a>
        ),
      },
    ];
  },
};

export default studentImportData;
