const css = `.emailRecipientSelector.ant-select-multiple .ant-select-selector {
  min-height: 200px;
  align-items: flex-start;
  background-color: #e6e9f0;
  border-bottom: 1px solid;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RpeGl0LmphaW4vZGF0YS1pbmdlc3QvYXBwcy9kYXRhLWluZ2VzdC11aS9zcmMvYXBwL2NvbnRhaW5lcnMvSW1wb3J0Tm90aWZpY2F0aW9ucyIsInNvdXJjZXMiOlsic3R5bGVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFDQSIsInNvdXJjZXNDb250ZW50IjpbIi5lbWFpbFJlY2lwaWVudFNlbGVjdG9yLmFudC1zZWxlY3QtbXVsdGlwbGUgLmFudC1zZWxlY3Qtc2VsZWN0b3Ige1xuICBtaW4taGVpZ2h0OiAyMDBweDtcbiAgYWxpZ24taXRlbXM6IGZsZXgtc3RhcnQ7XG4gIGJhY2tncm91bmQtY29sb3I6ICNlNmU5ZjA7XG4gIGJvcmRlci1ib3R0b206IDFweCBzb2xpZDtcbn1cbiJdfQ== */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
