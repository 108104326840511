import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import { defaultNavParentMapping, navianceParentPSFields, parentUserNameFields } from '../../utils/fieldMapUtils';
import SisMatchDescriptionHeader from '../../components-v2/SisMatchDescriptionHeader';
import SisWizardHeader from '../../components-v2/SisWizardHeader';
import Title from 'antd/lib/typography/Title';

export const ParentUserNameHeader = () => {
  return (
    <>
    <Title className="titleField" data-cy="data-import-heading" level={5}>
      Username
    </Title>
    <p>Do not select a username if parents do not need to login to Naviance (Student login only)</p>
    </>
  );
};

const SisParentRecordsMatching = (props: WizardProps): React.ReactElement => {
  const sections = [
    {
      tableHeader: <SisWizardHeader text={'Parent Records'} />,
      defaultFields: navianceParentPSFields,
      defaultBlankValue: '(Do not import)',
    },
    {
      tableHeader: <ParentUserNameHeader />,
      defaultFields: parentUserNameFields,
      defaultBlankValue: 'Choose an option',
    },
  ];

   // add custom headers as needed in future during implementation of processors
  const customHeaders = {
    custodial: 'is_custodial', // student parent link field
    receivesCommunication: 'receives_email_flag',
    fullName: 'full_name', // student parent link field
    student_dcid: 'student_dcid',
    // we need to set Has financial responsibility for child: N/A in student parent link field
    // we need to set occupation as employee by default in processors in parent table
  };

  return (
    <SisFieldMatching
      defaultMapping={defaultNavParentMapping}
      specificMapping={{ ...customHeaders }}
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/parent-recordstranslateConfig'}
      extensionsEndpoint={'data-ingest/sis/parent-extension-tables'}
      removeUnmappedHeaders={false}
      removeEmptyHeaders={false}
      sections={sections}
      header={<SisMatchDescriptionHeader />}
      {...props}
    />
  );
};

export default SisParentRecordsMatching;
