import * as React from 'react';
import { WizardProps } from '../../components-v2/Wizard';
import SisFieldMatching from './index';
import { defaultNavStaffMapping, navianceStaffPSFields } from '../../utils/fieldMapUtils';
import SisMatchDescriptionHeader from '../../components-v2/SisMatchDescriptionHeader';
import SisWizardHeader from '../../components-v2/SisWizardHeader';

const SisStaffRecordsMatching = (props: WizardProps): React.ReactElement => {
  const sections = [
    {
      tableHeader: <SisWizardHeader text={'Staff Records'}/>,
      defaultFields: navianceStaffPSFields,
      defaultBlankValue: '(Do not import)',
    }
  ];

  const customHeaders = {
    roleDefId: 'roledefid',
    staffStatus: 'staffstatus',
    isDistrict: 'isdistrict',
    isCounselor: 'iscounselor',
    schoolid: 'schoolid',
    entityId: 'schoolid',
    'SIS RoledefName': 'SIS RoledefName',
    'Nav Rolename': 'Nav Rolename'
  };

  return (
    <SisFieldMatching
      defaultMapping={defaultNavStaffMapping}
      specificMapping={ {...customHeaders} }
      allFieldsRequired={false}
      translateConfigEndPoint={'data-ingest/sis/parameter-group/staff-recordstranslateConfig'}
      extensionsEndpoint={'data-ingest/sis/staff-extension-tables'}
      removeUnmappedHeaders={false}
      removeEmptyHeaders={false}
      sections={sections}
      header={<SisMatchDescriptionHeader />}
      {...props}
    />
  );
};

export default SisStaffRecordsMatching;
