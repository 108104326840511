import * as React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';
import GenderCodeMapping from '../../components-v2/SisCodeMatching/gender';
import EthnicityCodeMapping from '../../components-v2/SisCodeMatching/ethnicity';
import CounselorCodeMapping from '../../components-v2/SisCodeMatching/counselor';
import HomeRoomMapping from '../../components-v2/SisCodeMatching/homeRoom';
import SisGroupMatching from '../../components-v2/SisCodeMatching/studentGroup';
import { getExtensionsConfig } from '../../utils/getSisExtensionsData';
const defaultGenderMapping: Record<string, (string | number)[]> = {
  U: [],
  M: [],
  F: [],
  X: [],
};
const defaultEthnicityMapping: Record<string, (string | number)[]> = {
  WH: [],
  BA: [],
  HI: [],
  AS: [],
  AI: [],
  PI: [],
  FI: [],
  MR: [],
  OT: [],
};

const SisStudentDefineCodes = (props: WizardProps): React.ReactElement => {
  const [parameterGroup, setParameterGroup] = React.useState({});
  const [translateConfig, setTranslateConfig] = React.useState<any>({
    headerMappings: {},
    valueMappings: {
      raceCodes: {},
      genderCodes: {},
      ethnicCode: [],
      counselorCodes: {},
      homeRooms: {},
      groupMapping: {},
    },
  });
  const [genderMapping, setGenderMapping] = React.useState(defaultGenderMapping);
  const [ethnicityMapping, setEthnicityMapping] = React.useState(defaultEthnicityMapping);
  const [showGender, setShowGender] = React.useState(false);
  const [showEthnicity, setShowEthnicity] = React.useState('');
  const [counselorMapping, setCounselorMapping] = React.useState({});
  const [counselorMappingV2, setCounselorMappingV2] = React.useState({});
  const [navCounselorGroupMap, setNavCounselorGroupMap] = React.useState({});
  const [showCounselorMapping, setShowCounselorMapping] = React.useState(false);
  const [showHomeRoom, setShowHomeRoom] = React.useState(false);
  const [homeRoomMapping, setHomeRoomMapping] = React.useState({});
  const [homeRoomExtentionConfig, setHomeRoomExtentionConfig] = React.useState(null);
  const [showStudentGroup, setStudentGroup] = React.useState(false);
  const [groupMapping, setGroupMapping] = React.useState({});

  const getTenantMeta = async () => {
    try {
      const { data } = await apiClient.get(`data-ingest/sis/tenant-meta`);
      if (data.sisIntegration === 'psSis') {
        setStudentGroup(true);
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Config', 'Server Error');
    }
  };
  const processConfig = () => {
    const { headerMappings, valueMappings } = translateConfig;
    let showEth = '';
    if (headerMappings?.race || headerMappings?.ethnicity_code_mapping || headerMappings?.ethnicCode) {
      showEth = headerMappings?.ethnicity_code_mapping || headerMappings?.race ? 'race' : 'ethnicCode';
      if (valueMappings?.raceCodes) {
        setEthnicityMapping(valueMappings.raceCodes);
      }
    } else if (headerMappings?.ethnicity || headerMappings?.federalRaceCode) {
      showEth = headerMappings?.ethnicity ? 'ethnicity' : 'federalRace';
      if (valueMappings?.ethnicCode) {
        setEthnicityMapping(
          valueMappings.ethnicCode.reduce((p, c) => {
            if (p[c.toValue]) {
              return { ...p, [c.toValue]: [...p[c.toValue], c.fromValue] };
            } else {
              return { ...p, [c.toValue]: [c.fromValue] };
            }
          }, {}),
        );
      }
    }
    if (headerMappings?.home_room || (headerMappings?.homeRoom && headerMappings?.homeRoom === 'homeRoom')) {
      //direct=home_room: "homeRoom" ---- custom homeRoom: "homeRoom"
      setShowHomeRoom(true);
      if (valueMappings?.homeRooms) {
        setHomeRoomMapping(valueMappings?.homeRooms);
      }
      if (headerMappings?.homeRoom && headerMappings?.homeRoom === 'homeRoom') {
        const extentionConfig = getExtensionsConfig(parameterGroup, 'homeRoom');
        setHomeRoomExtentionConfig(extentionConfig);
      }
    }
    setShowEthnicity(showEth);
    setGroupMapping(valueMappings?.groupMapping || {});
    setShowGender(headerMappings?.gender || headerMappings?.legal_gender);

    if (valueMappings?.genderCodes) {
      setGenderMapping(valueMappings.genderCodes);
    }
    setShowCounselorMapping(
      ((headerMappings?.counselorId || headerMappings?.counselor || headerMappings?.primary_counselor_id) && true),
    );
    if (valueMappings?.counselorCodes && Object.keys(valueMappings.counselorCodes).length > 0) {
      setCounselorMapping(valueMappings.counselorCodes);
    }
    else if (valueMappings?.counselorCodesV2) {
      setCounselorMappingV2(valueMappings.counselorCodesV2);
    }
  };

  const saveMapping = async (): Promise<boolean> => {
    const config = {
      ...translateConfig,
      valueMappings: {
        ...translateConfig.valueMappings,
        genderCodes: genderMapping,
        ethnicCode: (!showEthnicity && translateConfig.valueMappings.ethnicCode) || [],
        counselorCodes: showCounselorMapping && {},
        counselorCodesV2: showCounselorMapping && counselorMappingV2,
        counselorGroupMap: navCounselorGroupMap,
        homeRooms: homeRoomMapping,
        groupMapping: groupMapping,
      },
    };
    if (showEthnicity === 'race' || showEthnicity === 'ethnicCode') {
      config.valueMappings.raceCodes = ethnicityMapping;
      config.valueMappings.ethnicCode = [];
    } else if (showEthnicity === 'ethnicity') {
      config.valueMappings.ethnicCode = Object.keys(ethnicityMapping).reduce((p, c) => {
        return [...p, ...ethnicityMapping[c].map((val) => ({ toValue: c, fromValue: val }))];
      }, []);
    } else if (showEthnicity === 'federalRace') {
      config.valueMappings.ethnicCode = [
        { toValue: 'AI', fromValue: '2' },
        { toValue: 'AS', fromValue: '3' },
        { toValue: 'BA', fromValue: '4' },
        { toValue: 'HI', fromValue: '1' },
        { toValue: 'PI', fromValue: '5' },
        { toValue: 'WH', fromValue: '6' },
        { toValue: 'MR', fromValue: '7' },
      ];
    }
    if (Object.keys(config.headerMappings).length <= 5) {
      showNotification(NotificationTypes.error, 'Error Saving Mappings', 'Something went wrong.');
      return false;
    }
    const { data } = await apiClient.patch('data-ingest/sis/parameter-group/translateConfig', {
      ...parameterGroup,
      translateConfig: config,
    });

    setParameterGroup(data);
    setTranslateConfig(data.translateConfig);
    return true;
  };

  const getMappings = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/translateConfig');
      setParameterGroup(data);

      const { translateConfig = {} } = data;

      setTranslateConfig(translateConfig);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mappings', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    processConfig();
    getTenantMeta();
  }, [translateConfig]);

  return (
    <SisDefineCodes
      codesToDefine={[
        showStudentGroup && (
          <SisGroupMatching key="define-student-group" groupMapping={groupMapping} setGroupMapping={setGroupMapping} />
        ),
        showHomeRoom && (
          <HomeRoomMapping
            key="define-homeRoom"
            homeRoomMapping={homeRoomMapping}
            setHomeRoomMapping={setHomeRoomMapping}
            extentionConfig={homeRoomExtentionConfig}
          />
        ),
        showCounselorMapping && (
          <CounselorCodeMapping
            key="define-counselor"
            counselorMapping={counselorMapping}
            setCounselorMapping={setCounselorMapping}
            counselorMappingV2={counselorMappingV2}
            setCounselorMappingV2={setCounselorMappingV2}
            setNavCounselorGroupMap={setNavCounselorGroupMap}
            isStudentCounselorMapped={translateConfig?.headerMappings?.primary_counselor_id}
          />
        ),
        showGender && (
          <GenderCodeMapping key="define-gender" genderMapping={genderMapping} setGenderMapping={setGenderMapping} />
        ),
        showEthnicity && showEthnicity !== 'federalRace' && (
          <EthnicityCodeMapping
            mappingType={showEthnicity}
            key="define-ethnicity"
            ethnicityMapping={ethnicityMapping}
            setEthnicityMapping={setEthnicityMapping}
          />
        ),
      ].filter((code) => !!code)}
      saveMapping={saveMapping}
      getMappings={getMappings}
      {...props}
    />
  );
};

export default SisStudentDefineCodes;
