import * as React from 'react';
import SisDefineCodes from '.';
import { WizardProps } from '../../components-v2/Wizard';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { PSError, StaffUserType } from '../../utils/constants';
import { getExtensionsConfig } from '../../utils/getSisExtensionsData';
import { Col, Row, Tooltip } from 'antd';
import Prefix from '../../components-v2/SisCodeMatching/prefix';
import StaffRole from '../../components-v2/SisCodeMatching/roleAndJobFunction';
import _ from 'lodash';
import { QuestionCircleFilled } from '@ant-design/icons';

const SisStaffRecordsDefineCodes = (props: WizardProps): React.ReactElement => {
  const [staffSchoolRoles, setSchoolStaffRoles] = React.useState([]);
  const [staffDistrictRoles, setStaffDistrictRoles] = React.useState([]);
  const [showPrefixMapping, setShowPrefixMapping] = React.useState(false);
  const [prefixMapping, setPrefixMapping] = React.useState({});
  const [prefixExtensionConfig, setPrefixExtensionConfig] = React.useState({});
  const [sisRolesMap, setSisRolesMap] = React.useState<Record<string, string[]>>({});
  const [schoolRolesMapping, setSchoolRolesMapping] = React.useState({});
  const [navSchoolRolesMap, setNavSchoolRolesMap] = React.useState<Record<string, string[]>>({});
  const [districtRolesMapping, setDistrictRolesMapping] = React.useState({});
  const [navDistrictRolesMap, setNavDistrictRolesMap] = React.useState<Record<string, string[]>>({});
  const [parameterGroup, setParameterGroup] = React.useState({});
  const [translateConfig, setTranslateConfig] = React.useState<any>({
    valueMappings: {},
    headerMappings: {},
  });
const text=' Match the Role in Naviance to the SIS code(s) from RoleDef.Name. If matching multiple SIS codes to a Naviance field, then separate with commas.';
  const getStaffRoles = async () => {
    try {
      const { data } = await apiClient.get('data-ingest/sis/staff-role-list');
      const rolesMap = {};
      data.forEach((role) =>
        rolesMap[role.name]
          ? (rolesMap[role.name] = [...rolesMap[role.name], role.id])
          : (rolesMap[role.name] = [role.id]),
      );
      setSisRolesMap(rolesMap);
      const stfRoles = Object.keys(rolesMap).map((key) => {
        return {
          label: key,
          value: key,
        };
      });
      setSchoolStaffRoles(_.cloneDeep(stfRoles));
      setStaffDistrictRoles(_.cloneDeep(stfRoles));
    } catch (error) {
      console.error(error.message);
      if (error.message !== PSError) {
        showNotification(
          NotificationTypes.error,
          'Error Getting Sis Staff Roles',
          'Failure in getting data from server.',
        );
      }
    }
  };

  const processConfig = () => {
    const { headerMappings, valueMappings } = translateConfig;
    getStaffRoles();
    if (headerMappings.prefix === 'prefix') {
      setShowPrefixMapping(true);
      setPrefixExtensionConfig(getExtensionsConfig(parameterGroup, 'prefix'));
    }
    if (valueMappings?.schoolRolesMappingCodes) {
      setSchoolRolesMapping(valueMappings.schoolRolesMappingCodes);
    }
    if (valueMappings?.districtRolesMappingCodes) {
      setDistrictRolesMapping(valueMappings.districtRolesMappingCodes);
    }
    if (valueMappings?.prefixMappingCodes) {
      setPrefixMapping(valueMappings.prefixMappingCodes);
    }
  };

  const saveMapping = async (): Promise<boolean> => {
    const config = {
      ...translateConfig,
      valueMappings: {
        ...translateConfig.valueMappings,
        prefixMappingCodes: prefixMapping,
        sisRolesMapping: sisRolesMap, // sis roles map common for both school and district
        schoolRolesMappingCodes: schoolRolesMapping, // naviance role name versus sis role name mapping for school Level
        navianceSchoolRolesMapping: navSchoolRolesMap, // naviance school roles map
        districtRolesMappingCodes: districtRolesMapping, // naviance role name versus sis role name mapping for district Level
        navianceDistrictRolesMapping: navDistrictRolesMap, // naviance district roles map
      },
      removeEmptyHeaders: false,
      removeUnmappedHeaders: false,
    };
    const { data } = await apiClient.patch('data-ingest/sis/parameter-group/staff-recordstranslateConfig', {
      ...parameterGroup,
      translateConfig: config,
    });
    setParameterGroup(data);
    setTranslateConfig(data.translateConfig);
    return true;
  };

  const getMappings = async () => {
    try {
      const { data } = await apiClient.get('/data-ingest/sis/parameter-group/staff-recordstranslateConfig');
      setParameterGroup(data);
      const { translateConfig = {} } = data;
      setTranslateConfig(translateConfig);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mappings', 'Failure in getting data from server.');
    }
  };

  React.useEffect(() => {
    processConfig();
  }, [translateConfig]);

  return (
    <SisDefineCodes
      codesToDefine={[
        showPrefixMapping && (
          <Row className="mainSelectRow">
            <Col span={24}>
              <h2 className="infoHeading uploadHeading" style={{ marginTop: '1rem' }} data-test-id='all-user-type'>All User Type</h2>
              <Prefix
                prefixMapping={prefixMapping}
                setPrefixMapping={setPrefixMapping}
                prefixExtensionConfig={prefixExtensionConfig}
                dataTestId='all-user-type-prefix'
              />
            </Col>
          </Row>
        ),
        <Row className="mainSelectRow">
          <Col span={24}>
            <h2 className="infoHeading uploadHeading" style={{ marginTop: '1rem' }}>
              District User Type
            </h2>
            <StaffRole
              sisStaffRoles={staffDistrictRoles}
              roleMapping={districtRolesMapping}
              setRoleMapping={setDistrictRolesMapping}
              staffUserType={StaffUserType.district}
              setNavRolesMap={setNavDistrictRolesMap}
            />
          </Col>
        </Row>,
        <Row className="mainSelectRow">
          <Col span={24}>
            <h2 className="infoHeading uploadHeading" style={{ marginTop: '1rem' }}>
              School User Type  
              <Tooltip title={text}>
             <span> <QuestionCircleFilled /></span>
             </Tooltip>
             
            </h2>
            <StaffRole
              sisStaffRoles={staffSchoolRoles}
              roleMapping={schoolRolesMapping}
              setRoleMapping={setSchoolRolesMapping}
              staffUserType={StaffUserType.school}
              setNavRolesMap={setNavSchoolRolesMap}
            />
          </Col>
        </Row>,
      ]}
      saveMapping={saveMapping}
      getMappings={getMappings}
      {...props}
    />
  );
};

export default SisStaffRecordsDefineCodes;
