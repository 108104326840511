import React from 'react';
import { environment } from '../../environments/environment';
import { WizardProps } from '../components-v2/Wizard';
import { ExtendedTableData } from './getSisExtensionsData';

declare let window: Window;

interface Window {
  DI_CONTEXT: {
    API_URL: string;
    HIGHSCHOOL_ID: string;
    STUDENT_URL: string;
    TOU_URI: string;
  };
}
const { DI_CONTEXT } = window;

export const API_URL = DI_CONTEXT?.API_URL || environment.API_URL;

export const HIGHSCHOOL_ID = DI_CONTEXT?.HIGHSCHOOL_ID || '';
export const SIX_YEAR_HISTORY = 6;
export const UPLOAD_FILE_ENDPOINT = '/data-ingest/import-file';
export const CHANNEL_CONFIGURATIONS = '/data-ingest/configs/steps';

export const GET_CHANNEL_ENDPOINT = '/data-ingest/configs';

export const GET_COURSEPLANNER_CHANNEL_ENDPOINT = '/data-import/column-mappings/';

export const GET_PARAMETERGROUP_ENDPOINT = '/data-ingest/parametergroups';

export const TENANT_USER_ENDPOINT = 'data-ingest/tenantuser';
export const IMPORT_NAMES_ENDPOINT = 'data-ingest/configs/import-name';
export const ADD_EMAIL_ENDPOINT = 'data-ingest/configs/add-email';
export const REMOVE_EMAIL_ENDPOINT = 'data-ingest/configs/remove-email';
export const JOB_LOGS_ENDPOINT = 'data-ingest/jobs/logs';
export const ZONE_ENDPOINT = '/district/setupmain/zones.php';

export const TOS_ENDPOINT = '/district/tos.php';
export const PRIVACY_ENDPOINT =
  'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/privacy.html';
export const TOU_ENDPOINT = 'https://ui-production.papaya.naviance.com/naviance-static-assets/html/policies/tou.html';

export const TestImportTypes = [
  { value: 'SAT_SUBJECT_V1', text: 'SAT Subject Test' },
  { value: 'course-catalog', text: 'Course Catalog' },
  { value: 'ACT_ASPIRE_V1', text: 'American College Testing Aspire Test' },
  {
    value: 'ACT_V1',
    text: 'Legacy American College Testing Test (taken before Oct 2015)',
  },
  { value: 'ACT_V2', text: 'American College Testing Test' },
  { value: 'AP_V1', text: 'Advanced Placement Test' },
  { value: 'EXPLORE_V1', text: 'EXPLORE College Readiness Test' },
  { value: 'IB_V1', text: 'IB Test' },
  { value: 'ISE_V1', text: 'Independent School Exam' },
  { value: 'PRE_ACT_V1', text: 'PreACT Test' },
  { value: 'PSAT_V1', text: 'Pre-2015 PSAT/NMSQT (taken before Oct 2015)' },
  { value: 'PSAT_V2', text: 'Preliminary SAT' },
  { value: 'SAT_V1', text: 'SAT Reasoning Test (before March 2016)' },
  { value: 'SAT_V2', text: 'SAT Reasoning Test' },
  { value: 'SSAT_V1', text: 'SSAT Independent School Admission Test' },
  {
    value: 'STATE_AZ_AIMS_V1',
    text: "Arizona's Instrument to Measure Standard",
  },
  { value: 'STATE_CA_CST_V1', text: 'California Standards Tests (CSTs)' },
  {
    value: 'STATE_CO_CSAP_V1',
    text: 'Colorado Student Assesment Program (CSAP)',
  },
  { value: 'STATE_CT_CAPT_V1', text: 'Connecticut Academic Performance Test' },
  {
    value: 'STATE_DC_CAS_V1',
    text: 'District of Columbia Comprehensive Assessment System (DC-CAS)',
  },
  { value: 'STATE_IN_CORE40_V1', text: 'Indiana Core 40 ECA' },
  {
    value: 'STATE_IN_ISTEP_V1',
    text: 'Indiana Statewide Testing for Educational Progress-Plus (ISTEP+)',
  },
  {
    value: 'STATE_MA_MCAS_V1',
    text: 'Massachusetts Comprehensive Assessment System',
  },
  { value: 'STATE_MN_MCA_V1', text: 'Minnesota Comprehensive Assessments' },
  {
    value: 'STATE_NJ_NJASK_V1',
    text: 'New Jersey Assessment of Skills and Knowledge',
  },
  {
    value: 'STATE_NJ_NJGEPA_V1',
    text: 'New Jersey Grade Eight Proficiency Assessment',
  },
  {
    value: 'STATE_NJ_NJHSPA_V1',
    text: 'New Jersey High School Proficiency Assessment',
  },
  { value: 'STATE_OH_OAT_V1', text: 'Ohio Achievement Test' },
  { value: 'STATE_OH_OGT_V1', text: 'Ohio Graduation Test' },
  {
    value: 'STATE_OR_OAKS_V1',
    text: 'Oregon Assessment of Knowledge and Skills (OAKS)',
  },
  {
    value: 'STATE_PA_PSSA_V1',
    text: 'Pennsylvania System of School Assessment (PSSA)',
  },
  { value: 'STATE_TX_EOC_V1', text: 'STAAR EOC (End of Course)' },
  {
    value: 'STATE_TX_STAAR_V1',
    text: 'State of Texas Assessments of Academic Readiness (STAAR)',
  },
  {
    value: 'STATE_TX_TAKS_V1',
    text: 'Texas Assessment of Knowledge and Skills (TAKS)',
  },
  { value: 'TERRA_NOVA_V1', text: 'TerraNova Standardized Achievement Test' },
  { value: 'TOEFL_V1', text: 'Test of English as a Foreign Language' },
];

export const PSError = 'PSError';

export enum ImportTypeParam {
  CourseCatalog = 'course-catalog',
  CourseMapping = 'course-mapping',
  StudentCourseData = 'student-course-data',
  Scholarship = 'SCHOLARSHIP_V1',
}

export const ImportTypeFileNames = {
  [ImportTypeParam.CourseCatalog]: 'CourseCatalog',
  [ImportTypeParam.CourseMapping]: 'CourseMapping',
  [ImportTypeParam.StudentCourseData]: 'StudentCourseData',
  [ImportTypeParam.Scholarship]: 'Scholarships',
};

export interface CoursePlannerImportData {
  importType: ImportTypeParam;
  file: File | null;
  saveSettings: boolean;
  importSettings: { overrideData: boolean };
  fileHeaders: string[];
  loading: boolean;
}

export enum StaffListType {
  teacher = 'Teacher',
  cousnelor = 'Counselor'
}

export enum StaffUserType {
  district = 'district',
  school = 'school'
}

export interface AvailableCodes extends ExtendedTableData {
  used?: boolean;
}

export const navDiplomaTypeData = [
  {
    description: 'Endorsed/Advanced Diploma',
    code: 'Endorsed/Advanced Diploma',
  },
  {
    description: 'General Education Development Credential(GED)',
    code: 'General Education Development Credential(GED)',
  },
  {
    description: 'High School Equivalency Credential(other than GED)',
    code: 'High School Equivalency Credential(other than GED)',
  },
  {
    description: 'International Baccalaureate',
    code: 'International Baccalaureate',
  },
  {
    description: 'Modified Diploma',
    code: 'Modified Diploma',
  },
  {
    description: 'Regular Diploma',
    code: 'Regular Diploma',
  },
  {
    description: 'Vocational Certificate',
    code: 'Vocational Certificate',
  },
  {
    description: 'Other',
    code: 'Other',
  },
];

export const navEthnicityData = [
  {
    description: 'N/A',
    code: 'UK',
  },
  {
    description: 'American Indian/Alaska Native',
    code: 'AI',
  },
  {
    description: 'Asian',
    code: 'AS',
  },
  {
    description: 'Black/African American',
    code: 'BA',
  },
  {
    description: 'Hispanic',
    code: 'HI',
  },
  {
    description: 'Multi Racial',
    code: 'MR',
  },
  {
    description: 'Pacific Islander/Native Hawaiian',
    code: 'PI',
  },
  {
    description: 'White',
    code: 'WH',
  },
];

export const cleverRaceCodes = [
  {
    description: 'Caucasian',
    code: 'Caucasian',
  },
  {
    description: 'Asian',
    code: 'Asian',
  },
  {
    description: 'Black or African American',
    code: 'Black or African American',
  },
  {
    description: 'American Indian',
    code: 'American Indian',
  },
  {
    description: 'Two or More Races',
    code: 'Two or More Races',
  },
  {
    description: 'Hawaiian or Other Pacific Islander',
    code: 'Hawaiian or Other Pacific Islander',
  },
  {
    description: 'Unknown',
    code: 'Unknown',
  },
];

export const navFerpaBlockData = [
  {
    description: 'Yes',
    code: 'Y',
  },
  {
    description: 'No',
    code: 'N',
  },
];

export const navGenderData = [
  {
    description: 'Not Specified',
    code: 'U',
  },
  {
    description: 'Male',
    code: 'M',
  },
  {
    description: 'Female',
    code: 'F',
  },
  {
    description: 'Non-Binary',
    code: 'X',
  },
];

export const cleverGenderCodes = [
  {
    displayValue: 'Not Specified',
    code: '',
  },
  {
    displayValue: 'Male',
    code: 'M',
  },
  {
    displayValue: 'Female',
    code: 'F',
  },
  {
    displayValue: 'Non-Binary',
    code: 'X',
  },
];

export const naviancePrefixes = [
  {
    description: 'Dr.',
    code: 'Dr.',
  },
  {
    description: 'Mr.',
    code: 'Mr.',
  },
  {
    description: 'Ms.',
    code: 'Ms.',
  },
  {
    description: 'Mrs.',
    code: 'Mrs.',
  },
  {
    description: 'Miss',
    code: 'Miss',
  },
  {
    description: 'Sr.',
    code: 'Sr.',
  },
  {
    description: 'Fr.',
    code: 'Fr.',
  },
  {
    description: 'Br.',
    code: 'Br.',
  },
  {
    description: 'Rev.',
    code: 'Rev.',
  },
  {
    description: 'Mx.',
    code: 'Mx.',
  },
  {
    description: 'Other',
    code: 'Other',
  },
]

export const psSisMeta = {
  clientId: 'wbdfwefhebhbvhv',
  discoveryUrl: 'https://foo.com',
  host: 'https://foo.com',
  sisIntegration: 'psSis',
};

export const eSPMeta = {
  clientId: 'wbdfwefhebhbvhv',
  discoveryUrl: 'https://foo.com',
  host: 'https://foo.com',
  sisIntegration: 'eSP',
};

export const cleverMeta = {
  cleverId: 'wbdfwefhebhbvhv',
  clientId: 'wbdfwefhebhbvhv',
  dataHost: 'https://foo.com',
  host: 'https://foo.com',
  sisIntegration: 'clever',
};

export const translateConfig = {
  psStudentDataSyncConfig: { classRank: [], extensions: [] },
  translateConfig: {
    headerMappings: {
      student_number: 'hsStudentId',
      classof: ['classYear', 'originalClassYear'],
    },
  },
};

export const emptyFunc = () : any => {
  return;
};

export const props = ( title: string ): WizardProps => {
  return {
    steps: [{ title, status: 'process' }],
    currentStep: 0,
    saveFn: emptyFunc,
    cancelFn: emptyFunc,
    continueFn: emptyFunc,
    previousFn: emptyFunc,
    changeStepFn: emptyFunc,
    saveAndCloseFn: emptyFunc,
    children: <></>,
    hideFooter: true
  };
}

export const gpaMethods = [
  { gpamethod: 'Simple' },
  { gpamethod: 'Simple Percent' },
  { gpamethod: 'SimpleCurrent' },
  { gpamethod: 'SimpleCurrent-Test' },
  {}, // API can return empty responses, so test that case.
];

export const DEFAULT_ROLL_OVER_DAY = 25;
export const DEFAULT_ROLL_OVER_MONTH = 7;
