import * as React from 'react';
import { Badge, Col, Row, Select, Space, Table } from 'antd';
import Text from 'antd/lib/typography/Text';
import Drawer from '../../components/Drawer';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { PSError } from '../../utils/constants';
import Wizard, { WizardProps } from '../../components-v2/Wizard';
import CustomFieldDrawer from '../../components-v2/SisCustomField';
import {
  addlEthnCodeMappingHeaders,
  addlESPEthnCodeMappingHeaders,
  alphabeticalFields,
  CustomMapping,
  ethnicCodeValueMappings,
  hiddenFields,
  NavianceField,
  swapMapping,
  addlCleverEthnCodeMappingHeaders,
} from '../../utils/fieldMapUtils';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { featureFlags } from '../../utils/featureFlags';
import { IntegrationContext } from '../../utils/context';
import { gte as isGreaterThanOrEqualTo } from 'semver';

interface ClassRankMap {
  gpa?: string;
  weightedGpa?: string;
  rank?: string;
  decile?: string;
}

interface SisGpaMethods {
  gpamethod: string;
  description?: string;
}

interface Section {
  hasGroup?: boolean;
  tableHeader?: React.ReactElement;
  defaultFields: NavianceField[];
  defaultBlankValue?: string;
}

interface SisFieldMatchingProps extends WizardProps {
  allFieldsRequired: boolean;
  defaultMapping: Record<string, string>;
  translateConfigEndPoint: string;
  header?: React.ReactElement;
  sections: Section[];
  gpaRequired?: boolean;
  extensionsEndpoint?: string;
  specificMapping?: Record<string, string | string[]>;
  sisIntegration?: string;
  notShared?: string[];
  removeUnmappedHeaders?: boolean;
  removeEmptyHeaders?: boolean;
}

const NotificationStyle = {
  backgroundColor: '#FFFDE4',
  borderLeft: 'solid',
  borderLeftColor: '#FCD702',
  padding: '16px 12px',
};

const AlertIconStyle = {
  width: '30px',
  verticalAlign: 'bottom',
  marginLeft: '5px',
};

const SisFieldMatching = (props: SisFieldMatchingProps): React.ReactElement => {
  const scedSubjectAreaTitle = 'SCED Subject_Area';
  const createBasedOnSchedSubjectArea = 'create_based_on_sced_subjectArea';
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [navMapping, setNavMapping] = React.useState(props.defaultMapping);
  const [originalMapping, setOriginalMapping] = React.useState(props.defaultMapping);
  const [section, setSection] = React.useState<Section[]>(props.sections);
  const [customMapping, setCustomMapping] = React.useState<Record<string, CustomMapping>>({});
  const [originalCustomMapping, setOriginalCustomMapping] = React.useState<Record<string, CustomMapping>>({});
  const [classRankMapping, setClassRankMapping] = React.useState<ClassRankMap>({});
  const [originalClassRankMapping, setOriginalClassRankMapping] = React.useState<ClassRankMap>({});
  const [firstSetup, setFirstSetup] = React.useState<boolean>(true);
  const [tableLoading, setTableLoading] = React.useState(true);
  const [disableFooter, setHideFooterComp] = React.useState(true);
  const [isRender, setIsRender] = React.useState(false);
  const [sisIntegration, setSisIntegration] = React.useState(props.sisIntegration);

  const [origValueMappings, setOrigValueMappings] = React.useState({});
  const { isCleverIntegration, pluginDetails } = React.useContext(IntegrationContext);

  React.useEffect(() => {
    setSection(props.sections);
  }, [props.sections]);

  const defaultFields = props.sections
    .map((section: Section): NavianceField[] => {
      return section.defaultFields;
    })
    .reduce((previousValue: NavianceField[], currentValue: NavianceField[]) => {
      return [...previousValue, ...currentValue];
    });
  const [matchedFields, setMatchedFields] = React.useState(
    Object.keys(navMapping).filter((key) => defaultFields.find((field) => field?.val === key)).length,
  );
  const [fieldDrawer, setFieldDrawer] = React.useState<string>();

  const [gpaMethods, setGpaMethods] = React.useState<SisGpaMethods[]>([]);

  const createCustomFieldDisplayName = (fieldName) => `(custom) ${fieldName}`;

  const renderDrawer = () => {
    if (fieldDrawer) {
      const { name: displayName } = defaultFields.find((field) => field.val === fieldDrawer);
      let tableName, fieldName;
      if (customMapping[fieldDrawer]) {
        tableName = customMapping[fieldDrawer].tableName;
        fieldName = customMapping[fieldDrawer].fieldName;
      }

      return (
        <Drawer
          visible={true}
          onClose={closeDrawer}
          destroyOnClose={true}
          content={
            <CustomFieldDrawer
              save={setCustomField}
              tableName={tableName}
              fieldName={fieldName}
              navianceField={displayName}
              extensionsEndpoint={props.extensionsEndpoint}
            />
          }
        />
      );
    }
  };

  const setCustomField = (tableName, fieldName, primaryKey) => {
    const navField = fieldDrawer;

    const updatedNavMapping = { ...navMapping };
    updatedNavMapping[navField] = navField;
    setNavMapping(updatedNavMapping);

    const updatedRankMapping = { ...classRankMapping };
    delete updatedRankMapping[navField];
    setClassRankMapping(updatedRankMapping);

    const updateCustomMappings = { ...customMapping };
    updateCustomMappings[navField] = {
      tableName,
      fieldName,
      displayName: createCustomFieldDisplayName(fieldName),
      dcIdColumn: primaryKey,
    };
    setCustomMapping(updateCustomMappings);

    closeDrawer();
  };

  const closeDrawer = () => {
    setFieldDrawer(null);
  };

  const setClassRankField = (navField, classRankVal) => {
    const updatedRankMapping = { ...classRankMapping };
    const updateCustomMappings = { ...customMapping };
    const updateNavMappings = { ...navMapping };

    if (classRankVal) {
      updatedRankMapping[navField] = classRankVal;
      updateNavMappings[navField] = navField;
    } else {
      delete updatedRankMapping[navField];
      delete updateNavMappings[navField];
    }

    delete updateCustomMappings[navField];

    console.log(updateNavMappings);

    setClassRankMapping(updatedRankMapping);
    setCustomMapping(updateCustomMappings);
    setNavMapping(updateNavMappings);
  };

  const addToRequired = (section, index) => {
    const deletedSection = section.defaultFields.splice(index, 1)[0];
    deletedSection.required = true;
    props.sections[0].defaultFields.push(deletedSection);
    setIsRender(true);
  };

  const removeFromRequired = (section, index) => {
    const deletedSection = section.defaultFields.splice(index, 1)[0];
    deletedSection.required = false;
    props.sections[1].defaultFields.push(deletedSection);
    setIsRender(false);
  };

  const findScedSubjectAreaIndex = () => {
    let scedIndex = null;
    const sectionData = [...props.sections];
    sectionData.filter((section) => {
      return section.defaultFields.filter((sectionName, index) => {
        if (sectionName.name === scedSubjectAreaTitle) {
          scedIndex = index;
          return index;
        } else {
          return true;
        }
      });
    });
    return scedIndex;
  };

  const changeMapping = (key: string, val: string) => {
    if (val === 'custom') {
      setFieldDrawer(key);
      if (featureFlags['feature.dataIngest.cplan.psi472']) {
        const sectionData = [...props.sections];
        const newScedSubjectArea = sectionData.filter((section) => {
          return section.defaultFields.filter((sectionName) => {
            if (sectionName.name === 'Subject Area' && isRender && key === 'Subject_Area') {
              const index = findScedSubjectAreaIndex();
              if (index) {
                removeFromRequired(section, index);
              }
            } else {
              return true;
            }
          });
        });
        setSection([...newScedSubjectArea]);
      }
      return;
    }
    if (['gpa', 'weightedGpa', 'rank', 'decile'].includes(key)) {
      setClassRankField(key, val);
      return;
    }

    const updatedMapping = { ...navMapping };
    if (val) {
      updatedMapping[key] = val;
    } else {
      delete updatedMapping[key];
    }

    delete customMapping[key];
    if (featureFlags['feature.dataIngest.cplan.psi472']) {
      const sectionData = [...props.sections];
      const newScedSubjectArea = sectionData.filter((section) => {
        return section.defaultFields.filter((sectionName, index) => {
          if (sectionName.name === scedSubjectAreaTitle && val === createBasedOnSchedSubjectArea) {
            addToRequired(section, index);
            return false;
          } else if (
            sectionName.name === scedSubjectAreaTitle &&
            isRender &&
            (val === 'sched_department' || val === 'sched_coursesubjectareacode')
          ) {
            removeFromRequired(section, index);
          } else {
            return true;
          }
        });
      });
      setSection([...newScedSubjectArea]);
    }
    setNavMapping(updatedMapping);
  };

  React.useEffect(() => {
    const normalFieldsMatched = Object.keys(navMapping)
      .filter((key) => defaultFields.find((field) => field?.val === key))
      .filter((key) => !hiddenFields.includes(key)).length;
    setMatchedFields(normalFieldsMatched);
    setSisIntegration(props.sisIntegration);
  }, [navMapping]);

  const updateNavMaps = (translateConfig) => {
    const { headerMappings } = translateConfig;

    // if client has latest plugin installed, then disregard old mapping for address related fields
    if (
      props.sisIntegration === 'psSis' &&
      (pluginDetails as any)?.version !== undefined &&
      isGreaterThanOrEqualTo((pluginDetails as any)?.version, '1.2.2')
    ) {
      Object.keys(headerMappings)?.forEach((key) => {
        if (headerMappings[key] === 'state' && key !== 'personaddress_state') {
          delete headerMappings[key];
        }
        if (headerMappings[key] === 'zip' && key !== 'personaddress_zip') {
          delete headerMappings[key];
        }
        if (headerMappings[key] === 'streetAddress' && key !== 'personaddress_street') {
          delete headerMappings[key];
        }
        if (headerMappings[key] === 'city' && key !== 'personaddress_city') {
          delete headerMappings[key];
        }
      });
    }

    if (headerMappings.ethnicity_code_mapping) {
      // Remove additional headers that aren't needed for display
      Object.keys(addlEthnCodeMappingHeaders).forEach((key) => {
        delete headerMappings[key];
      });
    }
    if (headerMappings.ethnicCode) {
      // Remove additional headers that aren't needed for display
      Object.keys(addlESPEthnCodeMappingHeaders).forEach((key) => {
        delete headerMappings[key];
      });
    }
    if (headerMappings.race) {
      Object.keys(addlCleverEthnCodeMappingHeaders).forEach((key) => {
        delete headerMappings[key];
      });
    }

    const swappedMapping = swapMapping(headerMappings);

    let newNavMapping;
    if (props.allFieldsRequired)
      newNavMapping = { ...navMapping, ...swappedMapping.headerMappings, username: translateConfig.username };
    else newNavMapping = { ...swappedMapping.headerMappings, username: translateConfig.username };

    setNavMapping(newNavMapping);
    setOriginalMapping(newNavMapping);
  };

  const updateCustomMaps = (extensions) => {
    const updatedCustomMapping: Record<string, CustomMapping> = {};

    extensions.forEach((table) => {
      table.columns.forEach((column) => {
        updatedCustomMapping[column.outputName] = {
          tableName: table.name,
          fieldName: column.name,
          displayName: createCustomFieldDisplayName(column.name),
          dcIdColumn: table.dcIdColumn,
        };
      });
    });

    setCustomMapping(updatedCustomMapping);
    setOriginalCustomMapping(updatedCustomMapping);
  };

  const updateClassRankMaps = (classRankData) => {
    setClassRankMapping(classRankData);
    setOriginalClassRankMapping(classRankData);
  };

  const setNewMappings = (data) => {
    const { translateConfig, psStudentDataSyncConfig } = data;
    if (translateConfig || psStudentDataSyncConfig) {
      setFirstSetup(false);
    }

    if (translateConfig) {
      if (translateConfig.headerMappings) {
        updateNavMaps(translateConfig);
      }
      if (translateConfig.valueMappings) {
        setOrigValueMappings(translateConfig.valueMappings);
      }
    }

    if (psStudentDataSyncConfig && psStudentDataSyncConfig.extensions) {
      updateCustomMaps(psStudentDataSyncConfig.extensions);
    }
    if (psStudentDataSyncConfig && psStudentDataSyncConfig.classRank) {
      updateClassRankMaps(psStudentDataSyncConfig.classRank);
    }
  };

  const getSisMap = async () => {
    try {
      const { data } = await apiClient.get(props.translateConfigEndPoint);
      if (featureFlags['feature.dataIngest.cplan.psi472']) {
        if (data.translateConfig?.headerMappings?.create_based_on_sced_subjectArea) {
          const sectionData = [...props.sections];
          const newScedSubjectArea = sectionData.filter((section) => {
            return section.defaultFields.filter((sectionName, index) => {
              if (sectionName.name === scedSubjectAreaTitle) {
                addToRequired(section, index);
                return false;
              } else {
                return true;
              }
            });
          });
          setSection([...newScedSubjectArea]);
          console.log('section', section);
        }
      }
      if (isMountedRef.current) setNewMappings(data);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Mapping', 'Server Error');
    }
  };

  const cancelMapping = () => {
    setNavMapping(originalMapping);
    setCustomMapping(originalCustomMapping);
    setClassRankMapping(originalClassRankMapping);
  };

  const resetToDefaults = () => {
    setNavMapping(props.defaultMapping);
    setCustomMapping({});
    setOriginalClassRankMapping({});

    if (featureFlags['feature.dataIngest.cplan.psi472']) {
      const sectionData = [...props.sections];
      const newScedSubjectArea = sectionData.filter((section) => {
        return section.defaultFields.filter((sectionName, index) => {
          if (sectionName.name === scedSubjectAreaTitle && isRender) {
            removeFromRequired(section, index);
          } else {
            return true;
          }
        });
      });
      setSection([...newScedSubjectArea]);
    }
  };

  const createUpdatedTranslateConfig = () => {
    const sisIdMapping = { sisId: 'dcid' };
    if (props.translateConfigEndPoint.includes('parent-')) {
      sisIdMapping.sisId = 'guardian_id';
      sisIdMapping['personDcid'] = 'person_dcid';
    }
    const swappedMapping = swapMapping({ ...navMapping, dcid: 'dcid', ...sisIdMapping , ...(props.specificMapping || {}) });
    const translateConfig = {
      headerMappings: { ...swappedMapping.headerMappings } as Record<string, string | string[]>,
      removeUnmappedHeaders: props.hasOwnProperty('removeUnmappedHeaders') ? props.removeUnmappedHeaders : true,
      removeEmptyHeaders: props.hasOwnProperty('removeEmptyHeaders') ? props.removeEmptyHeaders : true,
      username: swappedMapping.username,
      valueMappings: {
        ...origValueMappings,
      },
    };

    if (translateConfig.headerMappings.ethnicity) {
      translateConfig.valueMappings = {
        ...origValueMappings,
        ethnicCode: ethnicCodeValueMappings,
        ethnicity: [],
        raceCodes: {},
      };
    } else if (translateConfig.headerMappings.ethnicity_code_mapping) {
      translateConfig.valueMappings = {
        ...origValueMappings,
        ethnicity: ethnicCodeValueMappings,
        ethnicCode: [],
      };
      translateConfig.headerMappings = {
        ...translateConfig.headerMappings,
        ...addlEthnCodeMappingHeaders,
      };
    } else if (translateConfig.headerMappings.ethnicCode) {
      translateConfig.headerMappings = {
        ...translateConfig.headerMappings,
        ...addlESPEthnCodeMappingHeaders,
      };
    } else if (translateConfig.headerMappings.race) {
      translateConfig.headerMappings = {
        ...translateConfig.headerMappings,
        ...addlCleverEthnCodeMappingHeaders,
      };
    }

    if (isCleverIntegration) {
      translateConfig.headerMappings = {
        ...translateConfig.headerMappings,
        school: 'School_ID'
      }
      // If we're switching newly to race and hispanic_ethnicity, add default values
      if ((originalMapping.ethnicCode !== 'race' || firstSetup) && translateConfig.headerMappings.race) {
        translateConfig.valueMappings = {
          ...translateConfig.valueMappings,
          raceCodes: {
            AI: ['American Indian'],
            AS: ['Asian'],
            BA: ['Black or African American'],
            FI: [],
            HI: [],
            MR: ['Two or More Races'],
            OT: [],
            PI: ['Hawaiian or Other Pacific Islander'],
            UK: ['Unknown'],
            WH: ['Caucasian'],
          },
        };
      }
      // If gender is newly added, set default mappings.
      if (!originalMapping.gender || firstSetup) {
        translateConfig.valueMappings = {
          ...translateConfig.valueMappings,
          genderCodes: {
            F: ['F'],
            M: ['M'],
            U: [''],
            X: ['X'],
          },
        };
      }
    } else {
      // If we're switching newly to ethnicity_code_mapping, add default values
      if (
        (originalMapping.ethnicCode !== 'ethnicity_code_mapping' || firstSetup) &&
        translateConfig.headerMappings.ethnicity_code_mapping
      ) {
        translateConfig.valueMappings = {
          ...translateConfig.valueMappings,
          raceCodes: { AI: ['I'], AS: ['A'], BA: ['B'], HI: [], OT: [], PI: ['P'], WH: ['W'] },
        };
      }
      // If gender is newly added, set default mappings.
      if (!originalMapping.gender || firstSetup) {
        ['gender', 'legal_gender'].forEach((label) => {
          if (translateConfig.headerMappings[label]) {
            translateConfig.valueMappings = {
              ...translateConfig.valueMappings,
              genderCodes: { F: ['F'], M: ['M'], U: [], X: ['X'] },
            };
          }
        });
      }
    }

    return translateConfig;
  };

  const createUpdatedExtensions = () => {
    const extensions = Object.keys(customMapping).reduce((exts, navField) => {
      const { tableName, fieldName, dcIdColumn } = customMapping[navField];
      const columnConfig = { name: fieldName, outputName: navField };

      if (exts[tableName]) {
        exts[tableName].columns.push(columnConfig);
      } else {
        exts[tableName] = {
          name: tableName,
          columns: [columnConfig],
          dcIdColumn,
        };
      }
      return { ...exts };
    }, {});

    return Object.values(extensions);
  };

  const saveSisMap = async () => {
    const updatedTranslateConfig = createUpdatedTranslateConfig();
    if (props?.notShared?.length > 0) {
      updatedTranslateConfig['notShared'] = props.notShared;
    }
    const updatedExtensions = createUpdatedExtensions();
    const sisMap: { translateConfig: any; psStudentDataSyncConfig: { extensions: any; classRank: any } } = {
      translateConfig: updatedTranslateConfig,
      psStudentDataSyncConfig: {
        extensions: updatedExtensions,
        classRank: classRankMapping,
      },
    };

    const { data } = await apiClient.patch(props.translateConfigEndPoint, sisMap);
    setNewMappings(data);
  };

  const getGpaMethods = async () => {
    try {
      const { data } = await apiClient.get('data-ingest/sis/gpa-methods');

      if (isMountedRef.current) setGpaMethods(data);
    } catch (error) {
      console.error(error.message);
      if (error.message !== PSError) {
        showNotification(
          NotificationTypes.warning,
          'GPA Methods not found',
          'Class Rank must be enabled to import GPAs into Naviance. Set up Class Rank Calculation Method in PowerSchool SIS and try again.',
          NotificationStyle,
          <AlertYellowIcon style={AlertIconStyle} />,
        );
      }
    }
  };

  const save = async (saveAndContinue?: boolean) => {
    try {
      if (props.allFieldsRequired && defaultFields.length !== matchedFields) {
        showNotification(NotificationTypes.error, 'Please map all required fields before Saving', '');
        return;
      }
      await saveSisMap();

      if (saveAndContinue) {
        props.continueFn();
      } else {
        showNotification(NotificationTypes.success, 'Save Successful', '');
        props.saveFn();
      }
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Saving Mapping', 'Server Error');
    }
  };
  const cancel = (cancelAndBacktrack?: boolean) => {
    cancelMapping();

    if (cancelAndBacktrack) {
      props.previousFn();
    } else {
      props.cancelFn();
    }
  };
  const changeStep = (newStep: number) => {
    cancelMapping();
    props.changeStepFn(newStep);
  };

  const saveAndClose = async () => {
    await save();
    await cancel();
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    if (!props.pageLoading) {
      const promises = [];
      promises.push(getSisMap());
      if (props.gpaRequired) promises.push(getGpaMethods());
      void Promise.all(promises).finally(() => {
        if (isMountedRef.current) {
          setTableLoading(false);
          setHideFooterComp(false);
        }
      });
    }
    return () => (isMountedRef.current = false);   
  }, [props.pageLoading]);

  const DataImportTableColumns = (defaultBlankString: string) => {
    return [
      {
        title: 'Naviance Field',
        width: '50%',
        render: (nav) => (
          <span title={nav.name} data-test-id={`nav_field_${nav.val}${nav.required ? '_required' : ''}`}>
            {nav.name}
            {nav.required ? (
              <Badge
                count={'required'}
                style={
                  navMapping[nav.val] && !props.notShared?.includes(navMapping[nav.val])
                    ? {
                        backgroundColor: '#ffffff',
                        color: '#939d9d',
                        border: '1px solid #939d9d',
                        borderRadius: '4px',
                        marginLeft: '10px',
                      }
                    : { backgroundColor: '#f71900', borderRadius: '4px', marginLeft: '10px' }
                }
              />
            ) : (
              ''
            )}
          </span>
        ),
      },
      {
        title: isCleverIntegration ? 'Matching Clever Field' : 'Matching SIS Data Field',
        width: '50%',
        render: (nav) => (
          <Select
            placeholder={defaultBlankString}
            className="select"
            value={
              navMapping[nav.val]
                ? classRankMapping[nav.val] || customMapping[nav.val]?.displayName || navMapping[nav.val] || ''
                : ''
            }
            onChange={(val) => changeMapping(nav.val, val)}
            data-test-id={`${nav.val}_select`}
          >
            {!nav.required && (
              <Option
                className="selectOptions"
                key={`sis-${nav.val}-blank`}
                value={''}
                data-test-id={`${nav.val}_option_blank`}
              >
                {defaultBlankString}
              </Option>
            )}
            {nav.sisFields.map((field, ind) => {
              return (
                (field.field !== 'create_based_on_sced_subjectArea' ||
                  featureFlags['feature.dataIngest.cplan.psi472']) && (
                  <Option
                    className="selectOptions"
                    key={`sis-${nav.val}-${ind}`}
                    value={field.field}
                    data-test-id={`${nav.val}_option_${field.field}`}
                  >
                    {field.notShared ? `${field.label} (Field not shared by client)` : field.label}
                  </Option>
                )
              );
            })}
            {nav.gpaMethodOptions &&
              gpaMethods.map(
                (method, ind) =>
                  method.gpamethod && (
                    <Option
                      className="selectOptions"
                      key={`sis-${nav.val}-gpamethod-${ind}`}
                      value={method.gpamethod}
                      data-test-id={`${nav.val}_option_gpamethod_${method.gpamethod.replace(' ', '_')}`}
                    >
                      {method.description ||
                        `[ClassRank] ${nav.gpaMethodOptions} where GPAMethod is ${method.gpamethod}`}
                    </Option>
                  ),
              )}
            {nav.customFieldOption && (
              <Option className="selectOptions" key="sis-custom" value="custom" data-test-id="custom_option">
                Select custom field
              </Option>
            )}
          </Select>
        ),
      },
    ];
  };

  const mainContent = (
    <>
      <div className="subSections">
        <div className="subSection">
          {props.header ?? <></>}
          <Row justify="space-between" align="bottom" style={{ margin: '8px 0px' }}>
            <Col span="auto">
              <button className="button" onClick={() => resetToDefaults()} data-test-id="reset_map_btn">
                Reset to Defaults
              </button>
            </Col>
            <Col>
              <Space>
                <Text data-test-id="matched_fields">
                  <b>{matchedFields}</b> Fields Matched
                </Text>
                <Text data-test-id="unmatched_fields">
                  <b>{defaultFields.length - matchedFields}</b> Fields Unmatched
                </Text>
              </Space>
            </Col>
          </Row>
        </div>
        {!isRender &&
          section.map((section, index) => {
            if (sisIntegration !== 'psSis' && section.hasGroup) {
              return null;
            }
            return (
              <div className="subSection defineCodes" key={index}>
                {section.tableHeader ?? <></>}
                {section.defaultFields && section.defaultFields.length > 0 && (
                  <Table
                    rowKey="val"
                    columns={DataImportTableColumns(section.defaultBlankValue)}
                    dataSource={alphabeticalFields(section.defaultFields)}
                    pagination={false}
                    loading={props.pageLoading || tableLoading}
                  />
                )}
              </div>
            );
          })}

        {isRender &&
          featureFlags['feature.dataIngest.cplan.psi472'] &&
          section.map((section, index) => {
            return (
              <div className="subSection defineCodes" key={index}>
                {section.tableHeader ?? <></>}
                {section.defaultFields && section.defaultFields.length > 0 && (
                  <Table
                    rowKey="val"
                    columns={DataImportTableColumns(section.defaultBlankValue)}
                    dataSource={alphabeticalFields(section.defaultFields)}
                    pagination={false}
                    loading={tableLoading}
                  />
                )}
              </div>
            );
          })}
      </div>

      {renderDrawer()}
    </>
  );

  return (
    <Wizard
      steps={props.steps}
      currentStep={props.currentStep}
      continueFn={() => save(true)}
      previousFn={() => cancel(true)}
      saveFn={() => save()}
      cancelFn={() => cancel()}
      saveAndCloseFn={saveAndClose}
      changeStepFn={changeStep}
      hideHeaderButtons={true}
      hideFooter={disableFooter}
    >
      {mainContent}
    </Wizard>
  );
};

SisFieldMatching.defaultProps = {
  pageLoading: false,
};

export default SisFieldMatching;
