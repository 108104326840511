import * as React from 'react';
import { CloseOutlined } from '@ant-design/icons';
import { Row, Col, Table, Select, Tag } from 'antd';
import type { DiplomaTypeCode } from '../../../../../../libs/common-interfaces';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { changeMapping } from '../../utils/utils';
import { navDiplomaTypeData } from '../../utils/constants';

const tagColor = '#f5f5f5';
const tagTextColor = '#141497';

interface DiplomaTypeDisplay extends DiplomaTypeCode {
  used?: boolean;
}

interface DiplomaTypeCodeMappingProps {
  diplomaTypeMapping: Record<string, (string | number)[]>;
  setDiplomaTypeMapping: (diplomaTypeMapping: Record<string, (string | number)[]>) => void;
  headerMappings: { [key: string]: string };
  sisIntegration: string;
}

const DiplomaTypeCodeMapping = ({
  diplomaTypeMapping,
  setDiplomaTypeMapping,
  headerMappings,
  sisIntegration,
}: DiplomaTypeCodeMappingProps): React.ReactElement => {
  const { Option } = Select;
  const isMountedRef = React.useRef(null);

  const [loading, setLoading] = React.useState(false);
  const [diplomaTypeCodes, setDiplomaTypeCodes] = React.useState([]);

  const getDiplomaTypeCodes = async () => {
    try {
      const { data }: { data: any } = await apiClient.get('/data-ingest/sis/diploma-type-codes');
      if (isMountedRef.current) setDiplomaTypeCodes(data.diplomaTypeCodes);
    } catch (err) {
      console.error(err.message);
      showNotification(NotificationTypes.error, 'Error Getting Race Codes', 'Failure in getting data from server.');
    }
  };

  const loadPage = async () => {
    if (isMountedRef.current) setLoading(true);
    const { data } = await apiClient.get(`/data-ingest/sis/tenant-meta`);
    if (headerMappings?.['exitcode'] || sisIntegration === 'eSP') {
      await getDiplomaTypeCodes();
    }
    if (isMountedRef.current) setLoading(false);
  };

  const tagRender = (props) => {
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  React.useEffect(() => {
    isMountedRef.current = true;
    void loadPage();
    return () => (isMountedRef.current = false);
  }, []);

  const diplomaTypeTableColumns = [
    {
      title: 'Naviance Code',
      width: '40%',
      render: (data) => <>{data.description}</>,
    },
    {
      title: headerMappings?.['exitcode'] || sisIntegration === 'eSP' ? 'Select SIS Codes' : 'Enter SIS Codes',
      width: '60%',
      render: (data) => {
        if (headerMappings?.['exitcode'] || sisIntegration === 'eSP') {
          return (
            <Select
              className="select select-responsive"
              mode="multiple"
              tagRender={tagRender}
              allowClear
              value={diplomaTypeMapping[data.code]}
              onChange={(val) => changeMapping(data.code, val, diplomaTypeMapping, setDiplomaTypeMapping)}
              data-test-id={`diploma_type_select_${data.code}`}
            >
              {diplomaTypeCodes.map((diplomaType, ind) => (
                <Option
                  className="selectOptions"
                  key={`sis-${diplomaType.id}-${ind}`}
                  value={diplomaType.name}
                  data-test-id={`diploma_type_option_${diplomaType.id}`}
                >
                  ({diplomaType.name}) {diplomaType.valuet}
                </Option>
              ))}
            </Select>
          );
        }

        return (
          <Select
            mode="tags"
            size="large"
            tagRender={tagRender}
            defaultValue={diplomaTypeMapping[data.code]}
            style={{ width: '100%' }}
            options={[]}
            tokenSeparators={[',']}
            dropdownStyle={{ display: 'none' }}
            placeholder="Add custom codes"
            onChange={(change) => {
              changeMapping(data.code, change, diplomaTypeMapping, setDiplomaTypeMapping)
            }}
          />
        );
      },
    },
  ];

  return (
    <Row className="mainSelectRow">
      <Col span={18}>
        <h2 style={{ marginBottom: '0px' }}>Diploma Type</h2>
        <span>
          If matching multiple SIS codes to Naviance codes, then separate with commas. Unmapped SIS codes will be
          visible on the View Log page after importing.
        </span>
      </Col>
      <Col span={16}>
        <Table
          rowKey="code"
          columns={diplomaTypeTableColumns}
          dataSource={navDiplomaTypeData}
          pagination={false}
          loading={loading}
        />
      </Col>
    </Row>
  );
};

export default DiplomaTypeCodeMapping;
