import * as React from 'react';
import Col from '../../components-v2/Col';
import EmailRecipients from '../../components-v2/EmailRecipients';
import Row from '../../components-v2/Row';
import Spinner from '../../components-v2/Spinner';
import apiClient from '../../utils/apiClient';
import MainTemplate from '../Shell/MainTemplate';
import ImportNotificationsDrawer from './ImportNotificationsDrawer';
import { IntegrationContext } from '../../utils/context';

// local styling
import './styles.scss';
import ConfigureButton from '../../components-v2/Button/ConfigureButton';

const ImportNotifications = (): React.ReactElement => {
  const [drawer, setDrawer] = React.useState<null | 'email'>(null);
  const [splitEmails, setSplitEmails] = React.useState([]);
  const [notifications, setNotifications] = React.useState([]);
  const [addSave, setAddSave] = React.useState({ disabled: false, label: 'Add' });
  const [pageLoading, setPageLoading] = React.useState(true);
  const [type, setType] = React.useState(null);

  const { isCleverIntegration } = React.useContext(IntegrationContext);

  const getNotificationSettings = async () => {
    try {
      const notificationPromise = apiClient.get('/data-ingest/sis/parameter-group/notifications');
      const tenantPromise = apiClient.get(`/data-ingest/sis/tenant-meta`);

      const [{data: notificationData}, {data: tenantData}] = await Promise.all([notificationPromise, tenantPromise])

      if((isCleverIntegration && tenantData.sisIntegration !== 'clever') || (!isCleverIntegration && tenantData.sisIntegration === 'clever')) {
        //Intentionally throwing an error so that no email configurations setting is shown
        throw Error()
      }

      if (notificationData && notificationData.length) {
        setNotifications(notificationData);
      }
    } catch (e) {
      console.error('No notification settings');
    } finally {
      setPageLoading(false);
    }
  };

  const updateNotificationSettings = async () => {
    const { data } = await apiClient.patch('/data-ingest/sis/parameter-group/notifications', {
      recipients: { ...notifications.reduce((p, c) => ({ ...p, [c.type]: c.recipients }), {}), [type]: splitEmails },
    });
    setNotifications(data || []);
    setSplitEmails([]);
    setDrawer(null);
  };

  React.useEffect(() => {
    getNotificationSettings();
  }, []);

  React.useEffect(() => {
    const anyBad = splitEmails.filter((email) => !validateEmail(email));
    setAddSave({ ...addSave, disabled: anyBad.length !== 0 });
  }, [splitEmails]);

  const validateEmail = (email: string): boolean => {
    // Regex to validate widely used email formats
    const emailRegexToTest =
      /^[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;
    // do not evaluate if email is empty
    if (email.length > 256 || !emailRegexToTest.test(email)) return false;
    // Check on the length of the domain part and the account part of an email which regex does not evaluate
    const [account, address] = email.split('@');
    if (account.length > 64) return false;
    const emailDomainParts = address.split('.');
    return !emailDomainParts.some((domainPart) => domainPart.length > 63);
  };

  const editEmails = (toEdit: string[], type: string) => {
    setAddSave({ label: 'Save', disabled: false });
    setType(type);
    setSplitEmails(toEdit);
    setDrawer('email');
  };

  return (
    <MainTemplate
      title="Manage Email Notifications"
      titleClassName="manageEmailTitle"
      titleTestId="manageEmailTitle"
      subheaderRSContent={<ConfigureButton />}
    >
      <div>
        {pageLoading ? (
          <Spinner />
        ) : (
          <>
            <p>You can control who receives notifications by data type.</p>
            <ImportNotificationsDrawer
              visible={drawer}
              setDrawer={setDrawer}
              type={type}
              validateEmail={validateEmail}
              splitEmails={splitEmails}
              setSplitEmails={setSplitEmails}
              addSave={addSave}
              updateNotificationSettings={updateNotificationSettings}
            />
            <Row>
              <Col span={20}>
                <EmailRecipients
                  notifications={notifications}
                  editRecipients={(toEdit, type) => {
                    editEmails(toEdit, type);
                  }}
                />
              </Col>
            </Row>
          </>
        )}
      </div>
    </MainTemplate>
  );
};

export default ImportNotifications;
