import * as React from 'react';
import SisStudentStartImport from '../SisStartImport/SisStudentStartImport';
import SisStudentsDefineCodes from '../SisDefineCodes/SisStudentDefineCodes';
import SisStudentSyncSettings from '../SisSyncSettings/SisStudentSyncSettings';
import SisImport from '.';
import SisStudentFieldMatching from '../SisFieldMatching/SisStudentFieldMatching';

export const StudentImportSteps = [
  { title: 'Match Fields', content: SisStudentFieldMatching, path: '/import-wizard/students/match-fields' },
  { title: 'Define Codes', content: SisStudentsDefineCodes, path: '/import-wizard/students/define-code' },
  { title: 'Sync Settings', content: SisStudentSyncSettings, path: '/import-wizard/students/sync-settings' },
  { title: 'Test / Start Import', content: SisStudentStartImport, path: '/import-wizard/students/test-start-import' },
];

const SisStudentImport = (): React.ReactElement => {
  return <SisImport steps={StudentImportSteps} />;
};

export default SisStudentImport;
