/* eslint-disable @typescript-eslint/no-explicit-any */
import type { SisStaffReconciled, SisStudentReconciled, SisReconciled } from '../../../../../../libs/common-interfaces';
import { Col, List, Row } from 'antd';
import * as React from 'react';
import { NotificationTypes, showNotification } from '../../components/Notifications';
import apiClient from '../../utils/apiClient';
import { PaginationConfig } from 'antd/lib/pagination';
import { IntegrationContext } from '../../utils/context';
import SisReconcileEntity from '../../components-v2/SisReconcileJob';

const PSDataImportJobReconcile = ({
  jobGuid,
  update,
  post,
  reconcileDisabled,
  setReconcileDisabled,
  dataType,
}: {
  jobGuid: string;
  update: (updatedReconciled: SisStudentReconciled | SisStaffReconciled) => void;
  post: () => void;
  reconcileDisabled: boolean;
  setReconcileDisabled: (updated: boolean) => void;
  dataType: string;
}): React.ReactElement => {
  const [toReconcile, setToReconcile] = React.useState<any>([]);
  const [loading, setLoading] = React.useState<boolean>();
  const [reconciled, setReconciled] = React.useState<SisStudentReconciled | SisStaffReconciled>({});
  const [totalRecords, setTotalRecords] = React.useState(0);
  const [matchedRows, setMatchedRows] = React.useState<
    Record<string, { student: string; create: boolean } | { staff: string; create: boolean }>
  >({});
  const { isLegacyCleverEnabled } = React.useContext(IntegrationContext);

  const pagination = {
    total: totalRecords,
    showSizeChanger: true,
    defaultPageSize: 10,
    showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
    pageSizeOptions: ['10', '50', '100'],
    onShowSizeChange: async (currentPage, size) => await fetchToReconcile(currentPage, size),
    onChange: async (page, size) => await fetchToReconcile(page, size),
  } as PaginationConfig;

  const fetchToReconcile = async (page = 1, size = 50) => {
    try {
      setLoading(true);
      const { data } = await apiClient.get(`/data-ingest/sis/jobs/${jobGuid}/reconcile?page=${page}&size=${size}`);
      setToReconcile(data.records ?? []);
      setTotalRecords(data.total ?? 0);
      setLoading(false);
    } catch (error) {
      console.log(error);
      showNotification(NotificationTypes.error, 'Error Getting Job Details', 'Failure in getting data from server.');
    }
  };

  const reconcile = async () => {
    setReconcileDisabled(true);
    post();
  };

  React.useEffect(() => {
    void fetchToReconcile();
  }, []);

  React.useEffect(() => {
    update(reconciled);
  }, [reconciled]);

  const updateReconciled = (update: SisStudentReconciled | SisStaffReconciled) => {
    const updatedReconciled = { ...reconciled };
    Object.keys(update).forEach((row) => {
      if (
        (update as SisStudentReconciled)[row].student ||
        (update as SisStaffReconciled)[row].staff ||
        update[row].create
      ) {
        updatedReconciled[row] = update[row];
      } else {
        delete updatedReconciled[row];
      }
    });
    setReconciled(updatedReconciled);
  };

  return (
    <>
      <br />
      <Row justify="space-between" align="middle">
        <Col span={20}>
          <h2>Resolve {totalRecords} Potential Updates</h2>
          <p>
            {Object.keys(reconciled).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled
              ? `After manually matching records below, select Process All Updates which starts a new import.`
              : `Below are the records for manual reconciliation, after actual import reconciliation option will be displayed.`}
          </p>
        </Col>
        <Col span={4}>
          {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
            <button
              className="button button-blue"
              style={{ float: 'right' }}
              disabled={Object.keys(toReconcile).length === 0 || reconcileDisabled || isLegacyCleverEnabled}
              onClick={() => {
                reconcile();
              }}
              data-test-id="process_btn"
            >
              Process All Updates
            </button>
          )}
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={24}>
          <List
            itemLayout="horizontal"
            dataSource={toReconcile}
            renderItem={(row: any) => (
              <SisReconcileEntity
                {...(row.student && { student: row.student })}
                {...(row.navStudents && { navStudents: row.navStudents })}
                {...(row.staff && { staff: row.staff })}
                {...(row.navStaffs && { navStaffs: row.navStaffs })}
                key={dataType === "students" || dataType === "alumni" ? row.student.dcid : `${row?.staff?.dcid}-${row?.staff?.entityId}-${row?.staff?.role}`}
                updateReconciled={updateReconciled}
                matchedRows={matchedRows}
                setMatchedRows={setMatchedRows}
                isDisabled={Object.keys(toReconcile).length === 0 || reconcileDisabled || isLegacyCleverEnabled}
                dataType={dataType}
              />
            )}
            loading={loading}
            pagination={pagination}
            size="large"
          />
          ,
        </Col>
      </Row>
      <Row justify="space-between" align="middle">
        <Col span={24}>
          {Object.keys(toReconcile).length > 0 && !reconcileDisabled && !isLegacyCleverEnabled && (
            <button
              className="button button-blue"
              style={{ float: 'right' }}
              disabled={Object.keys(toReconcile).length === 0 || reconcileDisabled || isLegacyCleverEnabled}
              onClick={() => {
                reconcile();
              }}
              data-test-id="process_btn"
            >
              Process All Updates
            </button>
          )}
        </Col>
      </Row>
    </>
  );
};

export default PSDataImportJobReconcile;
