import { Col, Row } from 'antd';
import * as React from 'react';
import { getJobStatusMsg } from '../../utils/jobStatus';
import AlertYellowIcon from '../../../assets/Icon/AlertYellowIcon';
import { API_URL } from '../../utils/constants';
import { SchoolDetailsData } from './SchoolDetails/SchoolDetails';

const parentImportData = {
  parentDetailsData: (
    hasIssues,
    job,
    errorColumnsPerCategory,
    errorRowsPerCategory,
    warningColumnsPerCategory,
    warningRowsPerCategory,
  ) => {
    const dataToUse = job.isTestImport
      ? job?.importResults?.readLegacyMetrics.summary
      : job?.importResults?.legacyImportMetrics.summary;
    const details = [
      {
        name: 'Records Added',
        key: 'Records Added',
        value: dataToUse.create || 0,
      },
      {
        name: 'Records Updated',
        key: 'Records Updated',
        value: dataToUse.update || 0,
      },
      {
        name: 'Records Made Inactive',
        key: 'Records Made Inactive',
        value: dataToUse.deactivated || 0,
      },
      {
        name: 'Records with No Changes',
        key: 'Records with No Changes',
        value: dataToUse.noChanges + dataToUse.discarded || 0,
      },
    ];

    const alerts = {
      name: 'Alerts',
      key: 'Alerts',
      value: (
        <Row>
          {hasIssues && (
            <Col span={1}>
              <AlertYellowIcon />
            </Col>
          )}
          <Col span={22}>
            <Row>
              <Col>
                <span style={{ fontWeight: 'bold' }}>
                  {(job?.recordsSkipped || 0) + (job?.validation?.warningCount || 0)}
                </span>
                {/* {job?.recordsSkipped > 0 ? ' Errors (Records Skipped)' : ''} */}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.invalidCount} Errors:`}</span> Records Skipped
                {Number(job?.validation?.invalidCount) > 0 && (
                  <ul className="custom-bullet">
                    {Object.keys(errorColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${errorRowsPerCategory[category]} ${category}`}:{' '}
                          </span>
                          {errorColumnsPerCategory[category].join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <Col span={24}>
                <span style={{ fontWeight: 'bold' }}>{`${job?.validation?.warningCount} Warnings:`}</span> Records Not
                Skipped
                {Number(job?.validation?.warningCount) > 0 && (
                  <ul className="custom-bullet">
                    {Object.keys(warningColumnsPerCategory || {}).map((category) => {
                      return (
                        <li key={category}>
                          <span style={{ fontWeight: 'bold' }}>
                            {`${warningRowsPerCategory[category]} ${category}`}:{' '}
                          </span>
                          {warningColumnsPerCategory[category].join(', ')}
                        </li>
                      );
                    })}
                  </ul>
                )}
              </Col>
            </Row>
            <Row>
              <a href={`${API_URL}/data-ingest/sis/jobs/${job?.id}/download/logs`} data-test-id={`match-export-link`}>
                Export Log for additional details
              </a>
            </Row>
          </Col>
        </Row>
      ),
    };

    details.push(alerts);
    return details;
  },
  parentSummaryData: (job) => {
    return [
      {
        name: 'Data Type',
        key: 'Data Type',
        value: job?.dataType,
      },
      {
        name: 'Current Status',
        key: 'Current Status',
        value: getJobStatusMsg(job),
      },
      {
        name: 'Start Time',
        key: 'Start Time',
        value: job?.start ? new Date(job.start).toLocaleString() : '',
      },
      {
        name: 'End Time',
        key: 'End Time',
        value: job?.end ? new Date(job.end).toLocaleString() : '',
      },
      {
        name: 'Source',
        key: 'Source',
        value: job.sis_source === 'clever' ? 'Clever' : job.sis_source || 'PowerSchool SIS',
      },
      {
        name: 'Number of Records',
        key: 'Number of Records',
        value: job?.validation?.totalDataCount ? job?.validation?.totalDataCount + job?.notTransfered : 0,
      },
      {
        name: 'Completed By',
        key: 'Completed By',
        value: job?.author || 'Automated Import',
      },
    ];
  },
  schoolDetailsData: (job, context) => {
    const readLegacyMetrics = job.importResults.readLegacyMetrics || {};
    const legacyImportMetrics = job.importResults.legacyImportMetrics || {};

    let dataToProcess;

    if (job.isTestImport) {
      dataToProcess = readLegacyMetrics;
    } else {
      dataToProcess = legacyImportMetrics;
    }

    const result = [];
    Object.keys(dataToProcess).forEach((school: string, index: number) => {
      if (school !== 'summary') {
        const schoolData: SchoolDetailsData = dataToProcess[school];
        schoolData['updateTransfer'] = `${schoolData.update}(${schoolData.transfer})`;
        schoolData['noChangesDiscarded'] = schoolData.noChanges + schoolData.discarded;
        if (schoolData.schoolId === context.districtId) {
          schoolData.schoolName = schoolData.schoolName + '(District)';
          result.unshift(schoolData);
        } else {
          result.push(schoolData);
        }
      }
    });
    return result;
  },
  undoData: (job) => {
    return [
      {
        name: 'Parent Undone',
        key: 'Parent Undone',
        value: job?.undo.undone,
      },
      {
        name: 'Parent Skipped',
        key: 'Parent Skipped',
        value: job?.undo.skipped,
      },
      {
        name: 'Parent Reactivated',
        key: 'Parent Reactivated',
        value: job?.undo.reactivated,
      },
    ];
  },
};

export default parentImportData;
