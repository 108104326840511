import * as React from 'react';
import { Select, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

interface WriteableTermDefineCodesProps {
  terms: string[];
  setTerms: (setTerms: string[]) => void;
  termValue: string;
}

const WriteableTermDefineCodes = ({
  terms,
  termValue,
  setTerms,
}: WriteableTermDefineCodesProps): React.ReactElement => {
  const tagRender = (props) => {
    const tagColor = '#f5f5f5';
    const tagTextColor = '#141497';
    const { value, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        key={value}
        onClose={onClose}
        closable={true}
        onMouseDown={onPreventMouseDown}
        style={{ color: tagTextColor, borderRadius: '15px', borderColor: tagTextColor }}
        closeIcon={<CloseOutlined style={{ color: tagTextColor }} />}
        color={tagColor}
      >
        {value}
      </Tag>
    );
  };

  return (
    <div className="subSection mainMatchFieldsSection mainSelectRow">
      <h2 className="infoHeading uploadHeading">Term</h2>
      <span className="pagepara">
        This value is based on <b>{termValue}</b>
      </span>
      <br />
      <span className="pagepara">
        Import courses with term values:{' '}
        <Select
          mode="tags"
          size="large"
          tagRender={tagRender}
          value={terms}
          style={{ width: 350 }}
          options={[]}
          tokenSeparators={[',']}
          dropdownStyle={{ display: 'none' }}
          onChange={(val) => {
            console.log('on change called ', val);
            setTerms(val);
          }}
          data-test-id={'terms_define_codes'}
        />
      </span>
    </div>
  );
};

export default WriteableTermDefineCodes;
