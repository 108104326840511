const css = `@charset "UTF-8";
.custom-bullet {
  list-style: none;
  margin-bottom: 0;
}

.custom-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.course-bullet {
  list-style: none;
  margin-bottom: 0;
}

.course-bullet li::before {
  content: "•";
  color: #fcd703;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -4em;
}

.remove-bullet li::before {
  content: "•";
  color: white;
  margin-left: -5em;
}

.imported-courses-list {
  margin: 0;
  list-style: none;
}

.export-link {
  float: right;
  margin-top: 1em;
  text-decoration: underline;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL1VzZXJzL2RpeGl0LmphaW4vZGF0YS1pbmdlc3QvYXBwcy9kYXRhLWluZ2VzdC11aS9zcmMvYXBwL2NvbnRhaW5lcnMvUFNEYXRhSW1wb3J0Sm9iU3VtbWFyeSIsInNvdXJjZXMiOlsic3R5bGVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IjtBQUFBO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTs7O0FBR0Y7RUFDRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBOzs7QUFHRjtFQUNFO0VBQ0E7OztBQUdGO0VBQ0U7RUFDQTtFQUNBIiwic291cmNlc0NvbnRlbnQiOlsiLmN1c3RvbS1idWxsZXQge1xuICBsaXN0LXN0eWxlOiBub25lO1xuICBtYXJnaW4tYm90dG9tOiAwO1xufVxuXG4uY3VzdG9tLWJ1bGxldCBsaTo6YmVmb3JlIHtcbiAgY29udGVudDogJ1xcMjAyMic7XG4gIGNvbG9yOiAjZmNkNzAzO1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICB3aWR0aDogMWVtO1xuICBtYXJnaW4tbGVmdDogLTFlbTtcbn1cblxuLmNvdXJzZS1idWxsZXQge1xuICBsaXN0LXN0eWxlOiBub25lO1xuICBtYXJnaW4tYm90dG9tOiAwO1xufVxuXG4uY291cnNlLWJ1bGxldCBsaTo6YmVmb3JlIHtcbiAgY29udGVudDogJ1xcMjAyMic7XG4gIGNvbG9yOiAjZmNkNzAzO1xuICBmb250LXdlaWdodDogYm9sZDtcbiAgZGlzcGxheTogaW5saW5lLWJsb2NrO1xuICB3aWR0aDogMWVtO1xuICBtYXJnaW4tbGVmdDogLTRlbTtcbn1cblxuLnJlbW92ZS1idWxsZXQgbGk6OmJlZm9yZSB7XG4gIGNvbnRlbnQ6ICdcXDIwMjInO1xuICBjb2xvcjogd2hpdGU7XG4gIG1hcmdpbi1sZWZ0OiAtNWVtO1xufVxuXG4uaW1wb3J0ZWQtY291cnNlcy1saXN0IHtcbiAgbWFyZ2luOiAwO1xuICBsaXN0LXN0eWxlOiBub25lO1xufVxuXG4uZXhwb3J0LWxpbmsge1xuICBmbG9hdDogcmlnaHQ7XG4gIG1hcmdpbi10b3A6IDFlbTtcbiAgdGV4dC1kZWNvcmF0aW9uOiB1bmRlcmxpbmU7XG59XG4iXX0= */`;
document.head
    .appendChild(document.createElement("style"))
    .appendChild(document.createTextNode(css));
export {css};
