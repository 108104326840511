import * as React from 'react';

const SisMatchDescriptionHeader = () : React.ReactElement => {
    return (
        <>
            <h3>Define the import by matching the Naviance field to corresponding SIS data field.</h3>
            <br />
        </>
    );
};

export default SisMatchDescriptionHeader;