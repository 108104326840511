import * as React from 'react';
import SisParentFieldsMatching from '../SisFieldMatching/SisParentFieldMatching';
import SisImport from '.';
import SisParentSyncSettings from '../SisSyncSettings/SisParentSyncSettings';
import SisParentStartImport from '../SisStartImport/SisParentStartImport';


export const ParentRecordsSteps = [
  { title: 'Match Fields', content: SisParentFieldsMatching, path: '/import-wizard/parent/match-fields' },
  { title: 'Sync Settings', content: SisParentSyncSettings, path: '/import-wizard/parent/sync-settings' },
  { title: 'Test / Start Import', content: SisParentStartImport, path: '/import-wizard/parent/test-start-import' },
];

const SisParentRecords = (): React.ReactElement => {
  return <SisImport steps={ParentRecordsSteps} />;
};

export default SisParentRecords;